import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES } from './cartActionTypes';
import shopApi from '../../api/shop';
import productsApi from '../../api/products';


export function cartAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Producto "${product.name}" agregado al carrito!`);
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

// export async function UpdateProductStock(product, quantity) {
//     let prod = await productsApi.get(product.id);
//     //var newProd = prod;
//     let newProd={
//         stock: prod.stock - quantity,
//     };
//     return await productsApi.updateStock(newProd, product.id);
// }

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
   // UpdateProductStock(product, quantity);

    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

// export function cartRemoveAllItems() {
//     // sending request to server, timeout is used as a stub
//     return (dispatch) => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(cartRemoveAllItemsSuccess());
//                 resolve();
//             }, 500);
//         })
//     );
// }

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}
