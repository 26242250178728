/* eslint-disable no-unused-vars */
// react
import React from "react";

// third-party
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//storage
import { storageHelper } from "../../helpers/localStorage";
// api
import { securityApi } from "../../api/security";
//hashing
import { PasswordHelper } from "../../helpers/hashing";

// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import { AuthContext } from "../../helpers/auth";
import profileApi from "../../api/profile";
import { API_BASE_URL } from "../../environment/environment";
import { Route } from "react-router-dom/cjs/react-router-dom";

export default function IndicatorAccount(props) {
  const { userData } = props;
  const { Login, Logoff } = React.useContext(AuthContext);

  const [token, setToken] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [loadingLogin, setLoadingLogin] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");

  const location = useLocation();

  const Logout = () => {
    Logoff(null);
    localStorage.removeItem("order");
    localStorage.removeItem("orderdetails");
    localStorage.removeItem("state");
    
    window.location.replace("/");
  };

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Complete este campo";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Correo electrónico no válido";
    }
    return error;
  }

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Correo electrónico no válido")
      .required("Complete este campo"),
    password: Yup.string()
      .min(8, "Longitud mínima es 8!")
      .max(20, "Longitud máxima es 20!")
      .required("Complete este campo"),
  });

  function SignIn(data, { resetForm }) {
    setLoadingLogin(true);
    var authData = {
      email: data.email,
      password: PasswordHelper.createHash(data.password),
    };
    securityApi.signin(authData).then((response) => {
      if (!response.error) {
        setTimeout(() => {
          profileApi.getByUserId(response.user.id).then((r) => {
            let d = {};
            if (r && r.length > 0) {
              d = r[0];
            }
            var sessData = {
              token: response.token,
              id: response.user.id,
              email: response.user.email,
              role: response.user.role,
              name: d.name!==undefined ? d.name : "Usuario",
              avatar: d.photo,
            };
            //console.log(sessData.avatar);
            //            setImgUrl(sessData.avatar);
            storageHelper.setUserData(sessData);
            Login(sessData);
            setLoadingLogin(false);
            toast.info(`Bienvenido a Offix ${sessData.name}`);
          });
          setTimeout(() => {
            window.location.replace(location.pathname);
          }, 1000);
        }, 500);
        
      } else {
        setTimeout(() => {
          setLoadingLogin(false);
          toast.error(response.error.message);
        }, 500);
      }
    });
  }

  const dropdown = (
    <div className="account-menu">
      { !userData.token && (
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={SignInSchema}
          onSubmit={(values, { resetForm }) => {
            SignIn(values, { resetForm });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form className="account-menu__form">
              <div className="account-menu__form-title">ACCEDER A OFFIX</div>
              <div className="form-group">
                <label htmlFor="header-signin-email" className="sr-only">
                  Correo Electronico
                </label>
                <Field
                  name="email"
                  placeholder="Correo electrónico"
                  validate={validateEmail}
                  className="form-control form-control-sm"
                />
                {errors.email && touched.email ? (
                  <div className="text-danger small">{errors.email}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="header-signin-password" className="sr-only">
                  Contraseña
                </label>
                <div className="account-menu__form-forgot">
                  <Field
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    className="form-control"
                  />
                  {errors.password && touched.password ? (
                    <div className="text-danger small">{errors.password}</div>
                  ) : null}
                  <Link
                    to="/account/forgotpassword"
                    className="account-menu__form-forgot-link"
                  >
                    Recuperar
                  </Link>
                </div>
              </div>
              <div className="form-group account-menu__form-button">
                {!loadingLogin && (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Entrar
                  </button>
                )}
                {loadingLogin && (
                  <button className="btn btn-primary" disabled>
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Espere..
                  </button>
                )}
              </div>
              <div className="account-menu__form-link container">
                <div className="row">
                  <div className="col-3">
                    <Link to="/account/login">Regístrate</Link>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      {userData.token && <div className="account-menu__divider" />}
      {userData.token && (
        <Link to="/account/dashboard" className="account-menu__user">
          <div className="account-menu__user-avatar">
            {/* <img src={imgUrl} alt="avatar" style={{ width: 45, height: 40 }} /> */}
            {userData.avatar && (
              <img
                src={`${API_BASE_URL}api/files/${userData.avatar}`}
                alt="avatar"
                style={{ width: 45, height: 40 }}
              />
            )}
            {userData.avatar === "undefined" && (
              <img
                src="images/avatars/default-avatar.png"
                alt="avatar"
                style={{ width: 45, height: 40 }}
              />
            )}
            {!userData.avatar && (
              <img
                src="images/avatars/default-avatar.png"
                alt="avatar"
                style={{ width: 45, height: 40 }}
              />
            )}
            {/* <img src={`${API_BASE_URL}api/files/${userData.avatar}`} alt="avatar" style={{width:45, height:40}}/> */}
          </div>
          <div className="account-menu__user-info">
            <div className="account-menu__user-name">{userData.name}</div>
            <div className="account-menu__user-email ">{userData.email}</div>
          </div>
        </Link>
      )}
      {userData.token && <div className="account-menu__divider" />}
      {userData.token && (
        <ul className="account-menu__links">
          <li>
            <Link to="/account/profile">Editar mi Perfil</Link>
          </li>
          <li>
            <Link to="/account/orders">Mis órdenes</Link>
          </li>
          <li>
            <Link to="/account/ordersreset">Solicitudes Reset</Link>
          </li>
          <li>
            <Link to="/account/addresses">Mis Direcciones</Link>
          </li>
          <li>
            <Link to="/account/password">Cambiar mi Contraseña</Link>
          </li>
        </ul>
      )}
      {userData.token && (
        <ul className="account-menu__links">
          <div className="account-menu__divider" />
          <li>
            <div className="form-group account-menu__form-button">
              <button
                type="button"
                onClick={Logout}
                className="btn btn-primary btn-sm"
              >
                Cerrar sesión
              </button>
            </div>
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <Indicator
      url="/account/login"
      dropdown={dropdown}
      icon={<Person20Svg />}
    />
  );
}
