import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";
import axios from "axios";
//import reviewsData from "../fake-server/database/reviews";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
  Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const reviewsApi = {
  create: async (data) => {
    const response = await fetch(`${API_BASE_URL}api/reviews`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  update: async (data, id) => {
    const response = await fetch(`${API_BASE_URL}api/reviews/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.ok;
  },
  get: async (id) => {
    const response = await fetch(`${API_BASE_URL}api/reviews/${id}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
  getByProductId: async (product) => {
    //console.log(product)
    let response;
    try{
      response = await axios.get(`${API_BASE_URL}api/reviews/?filter[where][productId]=${product.id}`, {
        headers: headers,
      });
    }
    catch(e){
    }
    return response;
  },
  getAll: async () => {
    let response;
    try {
      response = await axios.get(`${API_BASE_URL}api/reviews`, {
        headers: headers
      });
    } catch (e) {
     
    }
    // if success return value
    return response;
  },
};

export  default reviewsApi;


