/* eslint-disable no-unused-vars */
// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {Formik,Form,Field} from 'formik';
import * as Yup from 'yup';
import "animate.css";
import Swal from "sweetalert2";
import { toast } from 'react-toastify'; 
// application
import PageHeader from '../shared/PageHeader';
//import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
// api
import {securityApi} from '../../api/security';
//hashing
import {PasswordHelper} from '../../helpers/hashing';
//storage
import { storageHelper } from '../../helpers/localStorage';

import {AuthContext} from '../../helpers/auth';
import { validateEmail } from "../../helpers/email";


export default function AccountPageLogin() {
    const [token, setToken] = React.useState('');
    const [endPoint, setEndPoint] = React.useState(null);
    const [loading, setLoading] =  React.useState(false);
    const [loadingLogin, setLoadingLogin] =  React.useState(false);

    const {Login} = React.useContext(AuthContext);

    const breadcrumb = [
        { title: 'Inicio', url: '' },
        { title: 'Registro', url: '' },
    ];

    function setEndpointURI(endpoint){
      var uri = "api/security/sign-up/" + endpoint;
      setEndPoint(uri); 
    } 

    const SignUpSchema = Yup.object().shape({
      email: Yup.string()
        .email("Correo electrónico no válido")
        .required("Complete este campo"),
      password: Yup.string()
        .min(8, "Longitud mínima es 8!")
        .max(20, "Longitud máxima es 20!")
        .required("Complete este campo"),
      confirmPassword: Yup.string()
        .min(8, "Longitud mínima es 8!")
        .required("Confirme la contraseña")
        .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
    });

    const SignInSchema = Yup.object().shape({
      email: Yup.string()
        .email("Correo electrónico no válido")
        .required("Complete este campo"),
      password: Yup.string()
        .min(8, "Longitud mínima es 8!")  
        .max(20, "Longitud máxima es 20!")
        .required("Complete este campo"),
    });
    
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
 

    function SignUp(data, { resetForm }) {
      if(!endPoint){
        toast.error("Especifique como desea registrarse (Persona o Empresa)");
        return;
      }
      setLoading(true);
      var obj = {
        email: data.email,
        password: PasswordHelper.createHash(data.password),
      };
      securityApi.signup(obj, endPoint).then(response => {
        //console.log(endPoint)
        if (!response.error) {
          setTimeout(() => {
            setLoading(false);
            // toast.info(
            //   "Registro realizado con éxito. Le hemos enviado un email para verificar su identidad"
            // );
            Toast.fire({
              icon: 'success',
              title: 'Registro realizado con éxito. Le hemos enviado un email para verificar su identidad'
            })
            resetForm();
          }, 500);
        } else {
          setTimeout(() => {
            setLoading(false);
            toast.error(response.error.message);
          }, 500);
        }
      });
    }  

    function SignIn(data, { resetForm }) {
      setLoadingLogin(true);
      var obj = {
        email: data.email,
        password: PasswordHelper.createHash(data.password)
      };
      securityApi.signin(obj).then(response => {
        if (!response.error) {
          setTimeout(() => {
            var sessData = {
              token: response.token,
              id: response.user.id,
              email: response.user.email,
              role: response.user.role
            };
            //var token = response.token;
            storageHelper.setUserData(sessData);
            setLoadingLogin(false);
            window.location.replace("/account/profile");
          }, 500);
          } else {
          setTimeout(() => {
            setLoadingLogin(false);
            toast.error(response.error.message);
          }, 500);
        }
      });
    }  


    return (
      <React.Fragment>
        <Helmet>
          <title>{`Login — ${theme.name}`}</title>
        </Helmet>

        <PageHeader header="Registrarme" breadcrumb={breadcrumb} />

        <div className="block">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-6 d-flex">
                <div className="card flex-grow-1 mb-md-0">
                  <div className="card-body">
                    <h3 className="card-title">Login</h3>
                    <Formik
                      initialValues={{
                        email: "",
                        password: ""
                      }}
                      validationSchema={SignInSchema}
                      onSubmit={(values, { resetForm }) => {
                        SignIn(values, { resetForm });
                      }}
                    >
                      {({ handleSubmit, errors, touched }) => (
                        <form>
                          <div className="form-group">
                            <label htmlFor="login-password">
                              Correo Electrónico
                            </label>
                            <Field
                              name="email"
                              validate={validateEmail}
                              className="form-control"
                            />
                            {errors.email && touched.email ? (
                              <div className="text-danger small">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <Field
                              name="password"
                              type="password"
                              className="form-control"
                            />
                            {errors.password && touched.password ? (
                              <div className="text-danger small">
                                {errors.password}
                              </div>
                            ) : null}
                            <small className="form-text text-primary">
                              <Link to="/">¿Olvidó su contraseña? </Link>
                            </small>
                          </div>
                          {/* <div className="form-group">
                            <div className="form-check">
                              <span className="form-check-input input-check">
                                <span className="input-check__body">
                                  <input
                                    id="login-remember"
                                    type="checkbox"
                                    className="input-check__input"
                                  />
                                  <span className="input-check__box" />
                                  <Check9x7Svg className="input-check__icon" />
                                </span>
                              </span>
                              <label
                                className="form-check-label"
                                htmlFor="login-remember"
                              >
                                Recordar contraseña
                              </label>
                            </div>
                          </div>
                          {!loadingLogin && (
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-success"
                            >
                              Entrar
                            </button>
                          )}
                          {loadingLogin && (
                            <button className="btn btn-primary" disabled>
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                              Espere..
                            </button>
                          )}
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div> */}
              <div className="col-md-8 d-flex mt-4 mt-md-0">
                <div className="card flex-grow-1 mb-0">
                  <div className="card-body">
                    <h3 className="card-title form-check-inline">Regístrate como </h3>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineTipoRegistro"
                        id="radioPersona"
                        value=""
                        onChange={(value)=>setEndpointURI(value.target.value)}
                      />
                      <label className="form-check-label" for="radioPersona">
                        PERSONA NATURAL
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineTipoRegistro"
                        id="radioBussines"
                        value="business"
                        onChange={(value)=>setEndpointURI(value.target.value)}
                      />
                      <label className="form-check-label" for="radioBussines">
                        EMPRESA / MIPYME
                      </label>
                    </div>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={SignUpSchema}
                      onSubmit={(values, { resetForm }) => {
                        SignUp(values, { resetForm });
                      }}
                    >
                      {({ handleSubmit, errors, touched }) => (
                        <Form className="pt-3">
                          <div className="form-group">
                            <label htmlFor="login-password">
                              Correo Electrónico
                            </label>
                            <Field
                              name="email"
                              validate={validateEmail}
                              className="form-control"
                            />
                            {errors.email && touched.email ? (
                              <div className="text-danger small">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <Field
                              name="password"
                              type="password"
                              className="form-control"
                            />
                            {errors.password && touched.password ? (
                              <div className="text-danger small">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="confirmPassword">
                              Confirmar Contraseña
                            </label>
                            <Field
                              name="confirmPassword"
                              type="password"
                              className="form-control"
                            />
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <div className="text-danger small">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>

                          {!loading && (
                            <button type="submit" className="btn btn-success">
                              Registrarse
                            </button>
                          )}
                          {loading && (
                            <button className="btn btn-primary" disabled>
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                              Espere..
                            </button>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}
