/* eslint-disable no-unused-vars */
import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
  //Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const constactApi = {
    storeMessage: async (data) => {
        const response = await fetch(`${API_BASE_URL}api/contactemails`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        return response.json();
    },
	sendMessage: async (data) => {
        const response = await fetch(`${API_BASE_URL}api/contactemails/sendemail`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        return response.json();
      },	
};


export default constactApi;