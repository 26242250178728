// react
import React from 'react';

// application
import SocialLinks from '../shared/SocialLinks';

export default function FooterNewsletter() {
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title"> Síguenos en las redes...</h5>
            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
