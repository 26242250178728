// react
import React, { Component, useRef } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import { toast } from "react-toastify";
import Swal from 'sweetalert2'


// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import sellOrdersApi from '../../api/sellOrders';
import sellOrdersDetailsApi  from '../../api/sellOrderDetails';
import errorHandler from '../../helpers/errorHandler';
import { storageHelper } from '../../helpers/localStorage';
import SecuencialApi from '../../api/secuencial';

import { cartRemoveItem ,cartUpdateQuantities } from '../../store/cart';
import addressApi from '../../api/address';

// import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);
        
        this.state = {
            payment: 'bank',
            acceptTerms: false,
            isDomicilio: false,
            loading: false,
            isOrdering: false,
            orderId: "",
            myAddresses: {},
            myAddressList: [],
            isVisibleList: false,
            notaOrden: "",
            shipping: 0.50,
        };
        this.isLogged();
    }

    isLogged = () =>{
        const authData = storageHelper.getUserData();
        if (!authData.token) {
            window.location.replace("/");
            return;
        }
        else return authData;
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    handleAcceptTermsChange = (event) => {
            this.setState({ acceptTerms: event.target.checked });
    };
    handleIsDomicilioChange = (event) => {
        this.setState({ isDomicilio: event.target.checked });
        if(event.target.checked)
            this.getPrincipalAddress();
    };

    redirect(orderId) {
        return <Redirect to={`/checkout/success/${orderId}`}  />;
    }

    getPrincipalAddress=(event)=>{
        var usr = this.isLogged();
        //console.log(usr);
        addressApi.getByUser(usr.id).then((resp) => {
          if (resp.error) {
            toast.error(errorHandler.parseError(resp.error));
            return;
          }
          else if (resp.length <= 0) {
            toast.error("No se encuentran direcciones de envío configuradas");
            return;
          } else {
            let addr = resp.filter((x) => x.isPrincipal);
            if (addr.length > 0) {
              this.setState({ myAddresses: addr[0] });
              this.setState({ myAddressList: resp });
              //console.log(this.state.myAddressList);
            } else {
              this.setState({ myAddresses: {} });
              this.setState({ myAddressList: [] });
            }
          }
        });
        //console.log(this.state.myAddresses);
    }

  //   getAddressList=()=>{
  //     var usr = this.isLogged();
  //     addressApi.getByUser(usr.id).then((resp) => {
  //       if (resp.error) {
  //         toast.error(errorHandler.parseError(resp.error));
  //         return;
  //       }
  //       else if (resp.length <= 0) {
  //         toast.error("No se encuentran direcciones de envío configuradas");
  //         return;
  //       } else {
  //         let addr = resp.filter((x) => x.isPrincipal);
  //         //console.log(addr[0]);
  //         //alert(addr[0])
  //         if (addr.length > 0) {
  //           this.setState({ myAddresses: addr[0] });
  //           //console.log(this.state.myAddresses);
  //         } else this.setState({ myAddresses: {} });
  //       }
  //     });
  //     //console.log(this.state.myAddresses);
  // }


    MakeOrder = () =>{
        const { cart, cartRemoveItem, cartUpdateQuantities} = this.props;
        const { quantities } = this.state;
        
        this.setState({loading: true});
        
        SecuencialApi.getByDocument("ORDEN")

        Swal.fire({
            title: 'Finalizar Pedido',
            text: "¿Deseas finalizar la solicitud?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showClass: {
              popup: 'animate__animated animate__bounceIn'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then((result) => {
            if (result.isConfirmed) {

                SecuencialApi.generarSecuencial("ORDEN").then((secResponse)=>{
                    
                    let myAddress = this.state.isDomicilio ? this.state.myAddresses.street + " #" + this.state.myAddresses.apartament + ", " + 
                    this.state.myAddresses.city.name + ", " + this.state.myAddresses.state.name : "";
                    
                    let orderData = {
                        noSellOrden: secResponse,
                        orderDate: new Date(),
                        status: "PENDIENTE",
                        description: this.state.isDomicilio ? document.getElementById('notaOrden').value : "",
                        subtotal: cart.subtotal,
                        total: !this.state.isDomicilio ? cart.total: cart.total + this.state.shipping,
                        paymentMethod: "",
                        shippingAddress: myAddress,
                        billingAddress: "",
                        clientId: localStorage.getItem('id'),
                        additionalLines:[
                          {
                            label: "Envio",
                            total: this.state.isDomicilio ? this.state.shipping : 0.0,
                          },
                        ]
                    };

                    this.setState({loading: true});

                    sellOrdersApi.create(orderData).then((response) => {
                      if (response.error) {
                        toast.error(errorHandler.parseError(response.error));
                        this.setState({ loading: false });
                      } else {
                        // orderData.additionalLines = [
                        //   {
                        //     label: "Shipping",
                        //     total: 0,
                        //   },
                        // ];
                        localStorage.setItem(
                          "order",
                          JSON.stringify(orderData)
                        );
                        localStorage.setItem(
                          "orderdetails",
                          JSON.stringify(cart.items)
                        );
                        cart.items.map((itm) => {
                          let detail = {
                            productId: itm.product.id,
                            price: itm.price,
                            quantity: itm.quantity,
                            amount: itm.total,
                            sellOrderId: response.id,
                          };
                          //Save Details
                          sellOrdersDetailsApi.create(detail);
                          //CLear Cart
                          cartRemoveItem(itm.id);
                          if (quantities != undefined && quantities != null) {
                            cartUpdateQuantities(quantities);
                          }
                        });
                        toast.info("Solicitud Completada");
                        this.setState({
                          loading: false,
                          isOrdering: true,
                          orderId: response.id,
                        });
                        this.redirect(response.id);
                      }
                    }); 
                });
            } else {
                this.setState({ loading: false, isOrdering: false });
            }
          })
    }

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                {/* <td><Currency value={extraLine.price} /></td> */}
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    {/* <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr> */}
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.quantity} x ${item.product.name} `}</td>
                <td>
                  {/* <Currency value={item.total} /> */}
                </td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Producto</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>
                          {/* Total */}
                        </th>
                        <td>
                          {/* <Currency value={cart.total} /> */}
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    showAddressList(){
      this.setState({isVisibleList: true});
    }

    ChangeAddress(address){
      this.setState({isVisibleList: false});
      this.setState({myAddresses: address});
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
              <Collapse
                key={payment.key}
                open={currentPayment === payment.key}
                toggleClass="payment-methods__item--active"
                render={renderPayment}
              />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    render() {
        const { cart } = this.props;

        if(this.state.isOrdering){
            this.setState({isOrdering: false});
            return <Redirect to={ `/shop/checkout/success/${this.state.orderId}`} />;
        }else 
        if (cart.items.length < 1) {
            return <Redirect to="/shop/catalog" />;
        }

        // console.log(this.state.myAddresses)
        const breadcrumb = [
            { title: 'Inicio', url: '' },
            { title: 'Carrito de Compras', url: '/shop/cart' },
            { title: 'Checkout', url: '' },
        ];
        const position = [20.385742463415305, -76.64653609662014]
        return (
          <React.Fragment>
            <Helmet>
              <title>{`Checkout — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Checkout" breadcrumb={breadcrumb} />

            <div className="checkout block">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                    <div className="card mb-0">
                      <div className="card-body">
                        <h3 className="card-title">Tu Orden</h3>

                        {this.renderCart()}

                        {/* {this.renderPaymentsList()} */}
                        <div className="checkout__agree form-group">
                          <div className="form-check">
                            <span className="form-check-input input-check">
                              <span className="input-check__body">
                                <input
                                  className="input-check__input"
                                  type="checkbox"
                                  id="checkout-terms"
                                  onChange={(value) => {
                                    this.handleIsDomicilioChange(value);
                                  }}
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                              </span>
                            </span>
                            <label
                              className="form-check-label"
                              htmlFor="checkout-terms"
                            >
                              Servicio a Domicilio
                            </label>
                          </div>
                        </div>
                        <div className="checkout__agree form-group">
                          <div className="form-check">
                            <span className="form-check-input input-check">
                              <span className="input-check__body">
                                <input
                                  className="input-check__input"
                                  type="checkbox"
                                  id="checkout-terms"
                                  onChange={(value) => {
                                    this.handleAcceptTermsChange(value);
                                  }}
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                              </span>
                            </span>
                            <label
                              className="form-check-label"
                              htmlFor="checkout-terms"
                            >
                              He leído y estoy de acuerdo con los
                              <Link to="/site/terms">
                                {" "}
                                términos y condiciones
                              </Link>
                              *
                            </label>
                          </div>
                        </div>

                        {!this.state.loading && (
                          <button
                            type="button"
                            disabled={!this.state.acceptTerms || this.state.isVisibleList}
                            className="btn btn-primary "
                            onClick={() => this.MakeOrder()}
                          >
                            Realizar Pedido
                          </button>
                        )}
                        {this.state.loading && (
                          <button className="btn btn-primary " disabled>
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                            Espere...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.isDomicilio &&
                    this.state.myAddresses.id !== undefined && (
                      <div className="col-12 col-lg-6 col-xl-7">
                        <div className="col-12 col-lg-12 col-xl-12">
                          <div className="card mb-lg-0">
                            <div className="card-body">
                              <h3 className="card-title">Detalles de Envío</h3>
                              {/* DIRECCION SELECCIONADA */}
                              {!this.state.isVisibleList && (
                                <div className="dashboard__address card address-card address-card--featured">
                                  {this.state.myAddresses &&
                                    this.state.myAddresses.isPrincipal && (
                                      <div className="address-card__badge">
                                        Principal
                                      </div>
                                    )}
                                  <div className="address-card__body">
                                    <div className="address-card__name ">{`${
                                      this.state.myAddresses &&
                                      this.state.myAddresses.name
                                    } ${
                                      this.state.myAddresses &&
                                      this.state.myAddresses.lastname
                                    }`}</div>
                                    <div className="address-card__row">
                                      {this.state.myAddresses.country &&
                                        this.state.myAddresses.country.name}
                                      <br />
                                      {this.state.myAddresses.state &&
                                        this.state.myAddresses.state.name}
                                      ,{" "}
                                      {this.state.myAddresses.city &&
                                        this.state.myAddresses.city.name}
                                      ,{" "}
                                      {this.state.myAddresses.state &&
                                        this.state.myAddresses.zip}
                                      <br />
                                      {this.state.myAddresses &&
                                        this.state.myAddresses.street}
                                      , {" #"}
                                      {this.state.myAddresses &&
                                        this.state.myAddresses.apartament}
                                    </div>
                                    <div className="address-card__row">
                                      <div className="address-card__row-title">
                                        Teléfono
                                      </div>
                                      <div className="address-card__row-content">
                                        {this.state.myAddresses &&
                                          this.state.myAddresses.phone}
                                      </div>
                                    </div>
                                    <div className="address-card__row">
                                      <div className="address-card__row-title">
                                        Email{" "}
                                      </div>
                                      <div className="address-card__row-content">
                                        {this.state.myAddresses &&
                                          this.state.myAddresses.email}
                                      </div>
                                    </div>
                                  </div>
                                  {!this.state.loading && (
                                    <button
                                      type="button"
                                      //disabled={!this.state.acceptTerms}
                                      className="btn btn-primary mt-2"
                                      onClick={() => this.showAddressList()}
                                    >
                                      Cambiar dirección de envío
                                    </button>
                                  )}
                                  {this.state.loading && (
                                    <button
                                      className="btn btn-primary "
                                      disabled
                                    >
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                      Espere...
                                    </button>
                                  )}
                                </div>
                              )}
                              {/* LISTA DE DIRECCIONES PARA SELECCIONAR */}
                              {this.state.isVisibleList &&
                                this.state.myAddressList.map(
                                  (address, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className=" card address-card address-card--featured"
                                      >
                                        <div className="address-card__body">
                                          <div className="address-card__name ">{`${
                                            address && address.name
                                          } ${
                                            address && address.lastname
                                          }`}</div>
                                          <div className="address-card__row">
                                            {address.country &&
                                              address.country.name}
                                            <br />
                                            {address.state &&
                                              address.state.name}
                                            ,{" "}
                                            {address.city && address.city.name},{" "}
                                            {address.state && address.zip}
                                            <br />
                                            {address && address.street}, {" #"}
                                            {address && address.apartament}
                                          </div>
                                          <button
                                            type="button"
                                            //disabled={!this.state.acceptTerms}
                                            className="btn btn-primary mt-2 btn-block"
                                            onClick={() =>
                                              this.ChangeAddress(address)
                                            }
                                          >
                                            Seleccionar
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              <div className="form-group  mt-2">
                                <label htmlFor="checkout-comment">
                                  Notas de la orden{" "}
                                  <span className="text-muted">(Opcional)</span>
                                </label>
                                <textarea
                                  id="notaOrden"
                                  className="form-control"
                                  rows="4"
                                />
                              </div>
                              {/* <MapContainer
                                    center={position}
                                    zoom={13}
                                    scrollWheelZoom={false}
                                  >
                                    <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  /> 
                                    <Marker position={position}>
                                      <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                      </Popup>
                                    </Marker>
                                  </MapContainer> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
