var cryptojs = require("crypto-js");
const secretSeed = '236*452A21asdEr4rfsds/*-';

export const PasswordHelper = {
  //Generar hash
  createHash: plainPassword => {
    const hashDigest = cryptojs.SHA256(plainPassword);
    const hmacDigest = cryptojs.HmacSHA256(hashDigest, secretSeed).toString();
    return hmacDigest;
  },
  compareHash: (newPassword, storedPassword) => {
    const hashDigest = cryptojs.SHA256(newPassword);
    const hmacDigest = cryptojs.HmacSHA256(hashDigest, secretSeed).toString();
    return (hmacDigest == storedPassword);
  }
};