// react
import React, { Component } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import "animate.css";
// application

// data stubs
import theme from '../../data/theme';
//Helpers
import { storageHelper } from "../../helpers/localStorage";
import { toast } from "react-toastify";
import sellResetsApi from '../../api/sellResets';
import { API_BASE_URL } from "../../environment/environment";
import "animate.css";
import Swal from "sweetalert2";

export default function AccountPageOrdersResets (){
  const [loading, setLoading] = React.useState(false);
  const [resetList, setResets] = React.useState([]);
  const [userAuth, setuserAuth] = React.useState({});
  const [solicitudes, setSolicitudes] = React.useState([]);

  let history = useHistory();


  React.useEffect(() => {
    const authData =  storageHelper.getUserData();
    setuserAuth(authData);

    if (!authData.token) {
      toast.error(`Inicie sesión para tener acceso a los resets`);
      history.push("/");
      return;
    }
    getMyResets();
   setInterval(()=>{
      getMyResets();
   }, 20000)
    
  }, []);

  const getMyResets = async()=>{
    const userAuth =  storageHelper.getUserData();
    sellResetsApi.getAll().then((response) => {
      setLoading(false);
      console.log(response);
      if(response.length > 0){
        console.log(response.filter(x=>x.buyer.id == userAuth.id));
        setSolicitudes(response.filter(x=>x.buyer.id == userAuth.id));
      }
    });
  }

  const formLabel = <h5>Mis Solicitudes de Reset & Firmwares</h5>;
  
  return (
    <div className="card">
      <Helmet>
        <title>{`Mis Solicitudes de Reset/Firmware — ${theme.name}`}</title>
      </Helmet>
      <div className="card-header">{formLabel}
      {/* <button className='float-right btn btn-danger btn-xs'><span><i class="fa fa-check" aria-hidden="true"></i></span></button> */}
      
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-12 col-xl-12">
            <table className=" table-responsive table table-striped w-100">
              <thead>
                  <tr>
                    {/* <th>Equipo</th>
                    <th>Marca</th> */}
                    <th>Modelo</th>
                    <th>Archivo</th>
                    <th>Transacción</th>
                    <th> Descargar</th>
                    <th> Hardw ID</th>
                    <th> Llave</th>
                  </tr>
              </thead>
              {solicitudes &&
                solicitudes.map((r, index) => {
                  return (
                    <tr hid={index}>
                      {/* <th>{r.device  && r.device.name}</th>
                      <th>{r.brand && r.brand.name}</th> */}
                      <th><small>{r.reset && r.reset.model}</small></th>
                      <th><small>{r.reset && r.reset.typeFile}</small></th>
                      <th><small>{r.transactionCode}</small></th>
                      <th>
                        {r.descargable && (
                          <button
                            className="btn btn-xs btn-success rounded-right rounded-left"
                            onClick={() => {
                              var uri = `${API_BASE_URL}api/files/${r.reset.filename}.zip`;
                              //alert(uri)
                              fetch(uri)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  // Create blob link to download
                                  const url = window.URL.createObjectURL(
                                    new Blob([blob])
                                  );
                                  
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute(
                                    "download",
                                    `${r.reset.filename}.zip`
                                  );
                          
                                  // Append to html link element page
                                  document.body.appendChild(link);
                          
                                  // Start download
                                  link.click();
                          
                                  // Clean up and remove the link
                                  link.parentNode.removeChild(link);
                                });
                            }}
                          >
                            <span className="fa fa-download"></span>
                          </button>
                        )}
                      </th>
                      <th>
                        {r.hid != "" } <small>{r.hid}</small>
                        {r.descargable && <div>{r.hid == "" && <button
                            className="btn btn-xs btn-secondary rounded-right rounded-left"
                            onClick={() => {
                              Swal.fire({
                                title: "Enviar Hardware Id",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Aceptar",
                                input: "text",
                                inputLabel:
                                  "Introduzca el hardware Id generado en su equipo",
                                html: '<div className="card"><div className="card-header bg-danger text-white p-2"><b><span><i class="fa fa-info-circle" aria-hidden="true"></i> Importante:</small></b></div><div className="card-body p-2"> <span className=\'text-danger text-justify\'> Asegúrese de introducir correctamente los datos solicitados por Offix para la posterior activacón en su equipo. Offix no se hace responsable por los errores introducidos. Por favor lea los <a className=\'text-primary\' href=\'/site/terms\'>Términos y Condiciones</a> </small></div><br/><img ClassName="card" id="preview" src="/images/hid.png" /><br/> </div>',
                                showCancelButton: true,
                                confirmButtonText: "Enviar",
                                cancelButtonText: "Cancelar",
                                showLoaderOnConfirm: true,
                                inputValidator: (hid) => {
                                  // Si el valor es válido, debes regresar undefined. Si no, una cadena
                                  if (!hid) {
                                    return "Introduzca la llave ";
                                  } else {
                                    return undefined;
                                  }
                                },
                                showClass: {
                                  popup: "animate__animated animate__bounceIn",
                                },
                                hideClass: {
                                  popup: "animate__animated animate__fadeOutUp",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  Sendhid(result.value, r);
                                  getMyResets();
                                }
                              });
                            }}
                          >
                            <span className="fa fa-code"></span>
                          </button>}</div>}
                      </th>
                      <th><small><b>{r.key}</b></small></th>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  function Sendhid(v, r){
      //console.log(r);
      var obj = {
        hid: v
      };
      sellResetsApi.update(obj, r.id).then((response) => {
          toast.info("Hardware Id enviado");
      });
  }
}


