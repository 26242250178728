// react
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import "animate.css";
import Swal from "sweetalert2";
// application
import Pagination from '../shared/Pagination';
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import sellOrdersApi from '../../api/sellOrders';
import serviceOrderApi from '../../api/serviceOrder';

 class AccountPageOrders extends Component {
   constructor(props) {
     super(props);

     this.state = {
       orders: [],
       orderServices:[],
       filter:"ORDER",
       page: 1,
       isLoading: false,
       viewResponse: false,
     };
   }

   componentDidMount=()=>{
    this.getClientOrders();
    this.getClientServicesOrders();
   }

   getClientOrders = ()=>{
    this.setState({isLoading: true})
    sellOrdersApi.getByUserIdFullData(localStorage.getItem("id")).then((response) => {
      this.setState({ orders: response.data });
      this.setState({ isLoading: false });
    });
   }

   getClientServicesOrders=()=>{
    this.setState({isLoading: true})
    serviceOrderApi.getByUserIdFullData(localStorage.getItem("id")).then((response) => {
      this.setState({orderServices:response.data});
      this.setState({ isLoading: false });
    });
   }

   handlePageChange = (page) => {
     this.setState(() => ({ page }));
   };

   formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  currencyTemplate = (rowData) => {
    return this.formatCurrency(rowData.total);
  };
  
  linkOrderTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Link to={ `/shop/checkout/success/${rowData.id}`} >{rowData.id}</Link>
        </React.Fragment>
    );
}
   linkOrderTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Link to={ `/shop/checkout/success/${rowData.id}`} >{rowData.id}</Link>
        </React.Fragment>
    );
}

FilterOrders=(filter)=>{
  this.getClientServicesOrders();
  this.setState({filter: filter});
  console.log(this.state.orderServices)
}

//    renderOrderTable() {
//     const {layout} = this.props;
//      if (layout == "default") {
//        return (
//          <DataTable
//            value={this.state.orders}
//            className="table table-bordered table-striped table-responsive-lg table-hover table-borderless"
//          >
//           <Column field="id" header="Id" body={this.linkOrderTemplate}></Column>
//           <Column field="noSellOrden" header="No Orden" sortable ></Column>
//           <Column field="total" style={{ width: "150px " }} dataType='numeric'  align='right' header="Total"  sortable body={this.currencyTemplate}></Column>
//           <Column field="status" header="Estado" style={{ width: "150px " }} align='center'></Column>
//          </DataTable>
//        );
//      }
//    }

   render() {
     const { page, orders } = this.state;
     const ordersList = this.state.orders && this.state.orders.map((order) => (
        <tr key={order.id}>
            <td><Link to={`/account/orders/${order.id}`}>{`${order.id}`}</Link></td>
            <td>{order.noSellOrden}</td>
            <td>{new Date(order.orderDate).toLocaleDateString('es-es')}</td>
            <td>
            {order.status == "PENDIENTE" && order.status}{"  "}
            {order.status == "EN PROCESO" && (
              
              <button
                className="btn btn-xs btn-primary rounded"
                onClick={() => {
                  //this.setState({ viewResponse: true });
                  Swal.fire({
                    title: "Respuesta: " + order.noSellOrden,
                    text: order.respuesta,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",

                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                       // history.push("/");
                    }
                })    
                }}
              >
                <span className="fa fa-eye"> Ver Respuesta</span>
              </button>
            )}
          </td>
            <td align='right'>${order.total}</td>
        </tr>
    ));
    
    const ordersServicesList =
      this.state.orderServices &&
      this.state.orderServices.map((order, index) => (
        <tr key={index}>
          {/* <td><Link to={`/account/orders/${order.id}`}>{`${order.id}`}</Link></td> */}
          <td>{order.orderNum}</td>
          <td>{new Date(order.fecha).toLocaleDateString("es-es")}</td>
          <td>{order.service.name}</td>
          <td>{order.model}</td>
          <td>
          {order.status == "PENDIENTE" && order.status}{"  "}
            {order.status == "EN PROCESO" && (
              <button
                className="btn btn-xs btn-primary rounded"
                onClick={() => {
                  //this.setState({ viewResponse: true });
                  Swal.fire({
                    title: "Respuesta: " + order.orderNum,
                    text: order.respuesta,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",

                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                       // history.push("/");
                    }
                })    
                }}
              >
                <span className="fa fa-eye"> Ver Respuesta</span>
              </button>
            )}
          </td>
          {/* <td align='right'><button className='btn btn-xs btn-light' onClick={}><span className='fa fa-edit'></span></button></td> */}
          {/* <td align="right">
          <div className="text-center">
            <Link
              className="fa fa-edit"
              to={`/services/order/edit/${order.serviceId}`}
            >
             
            </Link>
          </div>
        </td> */}
        </tr>
      ));
     return (
       <div className="card">
         <Helmet>
           <title>{`Mis Órdenes — ${theme.name}`}</title>
         </Helmet>

         <div className="row card-header">
           <div className='col-md-4'><h5>Mis Órdenes</h5></div>
           <div className='col-md-8'>
            <select id='filterOrder' name='filterOrder' className='form-control' onChange={(e)=>{this.FilterOrders(e.currentTarget.value)}}>
            {/* <option value="">Todas</option>   */}
            <option value="ORDER">Ordenes de Venta</option>  
            <option value="SERVICIO">Ordenes de Servicio</option>  
            </select>
           </div>

         </div>
         <div className="card-divider" />
         <div className="card-table">
           <div>
             <table className="table-responsive-sm table table-striped">
               <thead>
                 {this.state.filter == "ORDER" && <tr >
                   <th>Id</th>
                   <th>No Order</th>
                   <th>Fecha</th>
                   <th>Estado</th>
                   <th>Total</th>
                 </tr>}
                 {this.state.filter == "SERVICIO" && <tr >
                   {/* <th>Id</th> */}
                   <th>No. Orden</th>
                   <th>Fecha</th>
                   <th>Servicio</th>
                   <th>Modelo</th>
                   <th>Estado</th>
                   {/* <th></th> */}
                 </tr>}
               </thead>
               <tbody>
               {this.state.isLoading && <button className='center btn btn-light btn-loading btn-xl'></button> }
                {!this.state.isLoading && this.state.filter == "ORDER" && ordersList}
                {!this.state.isLoading && this.state.filter == "SERVICIO" && ordersServicesList}
                </tbody>
             </table>
           </div>
         </div>
         <div className="card-divider" />
         {/* <div className="card-footer">
           <Pagination
             current={page}
             total={3}
             onPageChange={this.handlePageChange}
           />
         </div> */}
       </div>
     );
   }
 }



AccountPageOrders.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

AccountPageOrders.defaultProps = {
    layout: 'default',
};

export default AccountPageOrders;