// react
import React from 'react';

export default function BlockLoader() {
    return (
        <div style={{position:'absolute',left:"50%", width: "100", minHeight:'100px', backgroundColor:"rgb(255,255,255,0.5)"}}>
            <div className="block-loader__spinner" />
        </div>
    );
}
