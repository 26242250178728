/* eslint-disable no-unused-vars */
// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import CartIndicator from './IndicatorCart';
// import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorAccount from './IndicatorAccount';
import IndicatorSearch from './IndicatorSearch';
import NavLinks from './NavLinks';
import { Heart20Svg, LogoSmallSvg } from '../../svg';

//storage
import { storageHelper } from '../../helpers/localStorage';


function NavPanel(props) {
    const { layout, wishlist, userData } = props;
    
    // const [token, setToken]= React.useState(null);
    
    // React.useEffect(()=>{
    //     var tk = storageHelper.getToken();
    //     setToken(tk);
    // }, []);

    let logo = null;
    // let departments = null;
    let searchIndicator;

    if (layout === 'compact') {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/"><LogoSmallSvg /></Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    // if (layout === 'default') {
    //     departments = (
    //         <div className="nav-panel__departments">
    //             <Departments />
    //         </div>
    //     );
    // }

    return (
      <div className="nav-panel">
        <div className="nav-panel__container ml-5 mr-5">
          <div className="nav-panel__row">
            {logo}
            {/* {departments} */}

            <div className="nav-panel__nav-links nav-links">
              <NavLinks />
            </div>

            <div className="nav-panel__indicators">
              {searchIndicator}

              { (!userData || !userData.token) && (<div className="account-menu__form-link container">
                <div className="row ">
                  <div className="col-3">
                  <Link to="/account/login"><button type="button" className='btn  btn-info mt-2'>REGÍSTRATE</button></Link>
                  </div>
                </div>  
              </div>)}
              {/* { (!userData || !userData.token) && ( <button type="button" className='btn btn-block  btn-info mt-2'>REGÍSTRATE</button>)} */}
              {/* {userData && userData.token && (<Indicator url="/shop/wishlist" value={wishlist.length} icon={<Heart20Svg />} />)} */}

              <CartIndicator />

              <IndicatorAccount userData={userData} />
            </div>
          </div>
        </div>
      </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
