// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import sellOrdersApi from '../../api/sellOrders';

// data stubs
import theme from '../../data/theme';
import Currency from '../shared/Currency';

export default function AccountPageOrderDetails(props) {
    const {sellorderid} = props;
    const [order, setOrder] = React.useState({});    
    const [isLoading, setLoading] = React.useState(false);    

    //GetOrderDetails(sellorderid);
    React.useEffect(()=>{
        GetOrderDetails(sellorderid);
    },[])

    
    async function  GetOrderDetails (orderId){
        setLoading(true);
        let response = await sellOrdersApi.getByIdFullData(orderId);
        //console.log(response.data)
        setOrder(response.data)
        setLoading(false)
    }
    const renderItems=()=>{
        return (
            order.items &&
            order.items.map((item) => (
            <tr key={item.id} >
                <td >{`${item.product.name} × ${item.quantity}`}</td>
                <td align="right"><Currency value={parseFloat(item.price)} /></td>
            </tr>
            ))
        );
    }


    return (
      <React.Fragment>
        <Helmet>
          <title>{`Order Details — ${theme.name}`}</title>
        </Helmet>
        {isLoading && (
          <button className="btn btn-light btn-loading btn-xl"></button>
        )}

        {!isLoading && (
          <div className="card">
            <div className="order-header">
              <div className="order-header__actions">
                <Link to="/account/orders" className="btn btn-xs btn-secondary">
                  Volver
                </Link>
              </div>
              <h5 className="order-header__title">
                Orden: #{order.noSellOrden}
              </h5>
              <div className="order-header__subtitle">
                Puesta el{" "}
                <mark className="order-header__date">
                  {new Date(order.orderDate).toLocaleDateString()}
                </mark>{" "}
                y está actulalmente{" "}
                <mark className="order-header__status">{order.status}</mark>.
              </div>
            </div>
            <div className="card-divider" />
            <div className="card-table">
              <div className="table-responsive-sm">
                <table className="table table-condensed">
                  <thead>
                    <tr>
                      <th align='left'>Producto</th>
                      <th> <span className='float-right'>Total</span></th>
                    </tr>
                  </thead>
                  <tbody className="card-table__body card-table__body--merge-rows">
                    {/* <tr>
                                    <td>Electric Planer Brandix KL370090G 300 Watts × 2</td>
                                    <td>$1,398.00</td>
                                </tr>
                                 */}

                    {renderItems()}
                  </tbody>
                  <tbody className="card-table__body card-table__body--merge-rows">
                    <tr>
                      <th >Subtotal</th>
                      <td align="right">
                        <Currency value={parseFloat(order.subtotal)} />
                      </td>
                    </tr>
                    {order &&
                      order.additionalLines &&
                      order.additionalLines.map((line) => (
                        <tr>
                          <th>
                            {line.label}
                          </th>
                          <td align="right">
                            <Currency value={line.total} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th align="right">Total</th>
                      <td align="right">
                        <Currency value={parseFloat(order.total)} />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                            <div className="address-card__name">Helena Garcia</div>
                            <div className="address-card__row">
                                Random Federation
                                <br />
                                115302, Moscow
                                <br />
                                ul. Varshavskaya, 15-2-178
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">38 972 588-42-36</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">stroyka@example.com</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                            <div className="address-card__name">Helena Garcia</div>
                            <div className="address-card__row">
                                Random Federation
                                <br />
                                115302, Moscow
                                <br />
                                ul. Varshavskaya, 15-2-178
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">38 972 588-42-36</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">stroyka@example.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </React.Fragment>
    );
}
