import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
  Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const countriesApi = {
  create: async (data) => {
    const response = await fetch(API_BASE_URL + "api/countries", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  update: async (data, id) => {
    const response = await fetch(`${API_BASE_URL}api/countries/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  get: async (id) => {
    const response = await fetch(`${API_BASE_URL}api/countries/${id}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
  getAll: async () => {
    const response = await fetch(`${API_BASE_URL}api/countries/`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
};

export default countriesApi;
