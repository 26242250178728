import React, {useState}  from 'react';
import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import attributesData from './attributes';
import { getCategories, prepareCategory, walkTree } from '../database/categories';
import { getCategoriesAll } from '../endpoints/categories';
import productsApi from '../../api/products';
//import { categoriesListData, prepareCategory } from './categories';
// import attributesApi from '../../api/attributes';

const getId = makeIdGenerator();


// const productsDef = [
//   {
//     slug: "hdd-adata-black",
//     name: "Disco Duro Externo 2.5'' ",
//     price: 120.99,
//     images: [
//       "images/products/HDD-ADATA-BLACK.png",
//       "images/products/HDD-ADATA-BLACK-1.png"
//     ],
//     badges: "new",
//     rating: 3,
//     reviews: 12,
//     availability: "out-stock",
//     brand: "adata",
//     categories: ["componentes-pc", "disco-duro"],
//     attributes: [
//       { slug: 'color', values: ['dark-gray', 'shakespeare', 'black'] }
//   ],
//   },
//   {
//     slug: "laptop-acer-aspire5",
//     name: "Notebook Acer Aspire 5",
//     price: 560.99,
//     images: [
//       "images/products/acer-aspire5.png",
//       "images/products/acer-aspire5-1.png"
//     ],
//     badges: "new",
//     rating: 3.5,
//     reviews: 10,
//     availability: "in-stock",
//     brand: "acer",
//     categories: ["computadoras"],
//     attributes: [
//       { slug: "color", values: "red" }
//     ]
//   },{
//     slug: 'memoria-ram',
//     name: 'Memoria RAM Kinsgton Fury',
//     price: 33.50,
//     images: [
//         'images/products/ram-fury.png',
//         'images/products/ram-fury-1.png',
//     ],
//     badges: 'new',
//     rating: 4,
//     reviews: 12,
//     availability: 'in-stock',
//     brand: 'kingston',
//     categories: ["componentes-pc", "memoria-ram"],
//     attributes: [
//         { slug: 'color', values: 'black' },
//     ],
//   }
// ];
const categoriesDef = [
  {
      name: 'Instruments',
      slug: 'instruments',
      items: 272,
      children: [
          {
              name: 'Power Tools',
              slug: 'power-tools',
              image: 'assets/images/categories/category-1.jpg',
              items: 370,
              children: [
                  {
                      name: 'Drills & Mixers',
                      slug: 'drills-mixers',
                      items: 57,
                  },
                  {
                      name: 'Cordless Screwdrivers',
                      slug: 'cordless-screwdrivers',
                      items: 15,
                  },
                  {
                      name: 'Screwdrivers',
                      slug: 'screwdrivers',
                      items: 126,
                  },
                  {
                      name: 'Wrenches',
                      slug: 'wrenches',
                      items: 12,
                  },
                  {
                      name: 'Grinding Machines',
                      slug: 'grinding-machines',
                      items: 25,
                  },
                  {
                      name: 'Milling Cutters',
                      slug: 'milling-cutters',
                      items: 78,
                  },
                  {
                      name: 'Electric Spray Guns',
                      slug: 'electric-spray-guns',
                      items: 3,
                  },
              ],
          },
          {
              name: 'Hand Tools',
              slug: 'hand-tools',
              image: 'assets/images/categories/category-2.jpg',
              items: 134,
              children: [
                  {
                      name: 'Tool Kits',
                      slug: 'tool-kits',
                      items: 57,
                  },
                  {
                      name: 'Hammers',
                      slug: 'hammers',
                      items: 15,
                  },
                  {
                      name: 'Spanners',
                      slug: 'spanners',
                      items: 5,
                  },
                  {
                      name: 'Handsaws',
                      slug: 'handsaws',
                      items: 54,
                  },
                  {
                      name: 'Paint Tools',
                      slug: 'paint-tools',
                      items: 13,
                  },
              ],
          },
          {
              name: 'Machine Tools',
              slug: 'machine-tools',
              image: 'assets/images/categories/category-3.jpg',
              items: 302,
              children: [
                  {
                      name: 'Lathes',
                      slug: 'lathes',
                      items: 104,
                  },
                  {
                      name: 'Milling Machines',
                      slug: 'milling-machines',
                      items: 12,
                  },
                  {
                      name: 'Grinding Machines',
                      slug: 'grinding-machines',
                      items: 67,
                  },
                  {
                      name: 'CNC Machines',
                      slug: 'cnc-machines',
                      items: 5,
                  },
                  {
                      name: 'Sharpening Machines',
                      slug: 'sharpening-machines',
                      items: 88,
                  },
              ],
          },
          {
              name: 'Power Machinery',
              slug: 'power-machinery',
              image: 'assets/images/categories/category-4.jpg',
              items: 79,
              children: [
                  {
                      name: 'Generators',
                      slug: 'generators',
                      items: 23,
                  },
                  {
                      name: 'Compressors',
                      slug: 'compressors',
                      items: 76,
                  },
                  {
                      name: 'Winches',
                      slug: 'winches',
                      items: 43,
                  },
                  {
                      name: 'Plasma Cutting',
                      slug: 'plasma-cutting',
                      items: 128,
                  },
                  {
                      name: 'Electric Motors',
                      slug: 'electric-motors',
                      items: 76,
                  },
              ],
          },
          {
              name: 'Measurement',
              slug: 'measurement',
              image: 'assets/images/categories/category-5.jpg',
              items: 366,
              children: [
                  {
                      name: 'Tape Measure',
                      slug: 'tape-measure',
                      items: 57,
                  },
                  {
                      name: 'Theodolites',
                      slug: 'theodolites',
                      items: 5,
                  },
                  {
                      name: 'Thermal Imagers',
                      slug: 'thermal-imagers',
                      items: 3,
                  },
                  {
                      name: 'Calipers',
                      slug: 'calipers',
                      items: 37,
                  },
                  {
                      name: 'Levels',
                      slug: 'levels',
                      items: 14,
                  },
              ],
          },
          {
              name: 'Clothes and PPE',
              slug: 'clothes-and-ppe',
              image: 'assets/images/categories/category-6.jpg',
              items: 82,
              children: [
                  {
                      name: 'Winter Workwear',
                      slug: 'winter-workwear',
                      items: 24,
                  },
                  {
                      name: 'Summer Workwear',
                      slug: 'summer-workwear',
                      items: 87,
                  },
                  {
                      name: 'Helmets',
                      slug: 'helmets',
                      items: 9,
                  },
                  {
                      name: 'Belts and Bags',
                      slug: 'belts-and-bags',
                      items: 1,
                  },
                  {
                      name: 'Work Shoes',
                      slug: 'work-shoes',
                      items: 0,
                  },
              ],
          },
      ],
  },
  {
      name: 'Electronics',
      slug: 'electronics',
      items: 54,
  },
];


export default function productsData(){
    const brands = brandsData();
    const attributes = attributesData();
    const categorias = getCategoriesAll();
    //console.log(categorias);
    // const categorias = Promise.resolve(categoriesDef);
    const productList = productsApi.getAll();

    return attributes.then(attr =>{
        const attributesDef =  attr;
        return productList.then((productsDef) => {
          return brands.then((brand) => {
            return categorias.then((cat) => {
              //console.log(cat)
              const [categoriesTreeData, categoriesListData] = walkTree(cat);

              // console.log(productsDef.data);
              return productsDef.map((productDef) => {
                let badges = [];
                if (productDef.badges) {
                  badges = typeof productDef.badges === "string"
                      ? [productDef.badges]
                      : productDef.badges;
                }

                const categories = categoriesListData
                  .filter((category) =>
                    productDef.categories.includes(category.slug)
                  )
                  .map((category) => prepareCategory(category));

                const attributes = (productDef.attributes || []).map((productAttributeDef) => {
                    const attributeDef = attributesDef.find(
                      (x) => x.slug === productAttributeDef.slug
                    );

                    if (!attributeDef) {
                      return null;
                    }

                    let valuesDef = [];

                    if (typeof productAttributeDef.values === "string") {
                      valuesDef = [productAttributeDef.values];
                    } else if (productAttributeDef.values) {
                      valuesDef = productAttributeDef.values;
                    }

                    const values = valuesDef
                      .map((valueSlug) => {
                        const valueDef = attributeDef.values.find(
                          (x) => x.slug === valueSlug
                        );

                        if (!valueDef) {
                          return null;
                        }

                        return {
                          ...valueDef,
                          customFields: {},
                        };
                      })
                      .filter((x) => x !== null);

                    if (!values.length) {
                      return null;
                    }

                    return {
                      name: attributeDef.name,
                      slug: attributeDef.slug,
                      featured: !!productAttributeDef.featured,
                      values,
                      customFields: {},
                    };
                  })
                  .filter((x) => x !== null);

                let producto = {
                  id: productDef.id,
                  name: productDef.name,
                  sku: productDef.sku,
                  slug: productDef.slug,
                  price: productDef.price,
                  priceAlt: productDef.priceAlt,
                  priceMayoristaAlt: productDef.priceMayoristaAlt,
                  priceMayorista: productDef.priceMayorista,
                  compareAtPrice: productDef.compareAtPrice || null,
                  images: productDef.images.slice(),
                  badges: badges.slice(),
                  rating: productDef.rating,
                  reviews: productDef.reviews,
                  availability: productDef.availability,
                  stock: productDef.stock,
                  brand: brand.find((x) => x.slug === productDef.brand) || null, //brandsData.find((x) => x.slug === productDef.brand) || null,
                  description: productDef.description,
                  categories,
                  attributes,
                  customFields: {},
                };
                // console.log(producto)
                return producto;
              });
            });
          });
        });
    })
} 

// const productsData = productsDef.map((productDef) => {
//         let badges = [];
//         if (productDef.badges) {
//             badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
//         }
    
//         const categories = categoriesListData
//             .filter((category) => productDef.categories.includes(category.slug))
//             .map((category) => prepareCategory(category));
    
//         const attributes = (productDef.attributes || []).map((productAttributeDef) => {
//             const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);
    
//             if (!attributeDef) {
//                 return null;
//             }
    
//             let valuesDef = [];
    
//             if (typeof productAttributeDef.values === 'string') {
//                 valuesDef = [productAttributeDef.values];
//             } else if (productAttributeDef.values) {
//                 valuesDef = productAttributeDef.values;
//             }
    
//             const values = valuesDef.map((valueSlug) => {
//                 const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);
    
//                 if (!valueDef) {
//                     return null;
//                 }
    
//                 return {
//                     ...valueDef,
//                     customFields: {},
//                 };
//             }).filter((x) => x !== null);
    
//             if (!values.length) {
//                 return null;
//             }
    
//             return {
//                 name: attributeDef.name,
//                 slug: attributeDef.slug,
//                 featured: !!productAttributeDef.featured,
//                 values,
//                 customFields: {},
//             };
//         }).filter((x) => x !== null);

//         return {
//             id: getId(),
//             name: productDef.name,
//             sku: '83690/32',
//             slug: productDef.slug,
//             price: productDef.price,
//             compareAtPrice: productDef.compareAtPrice || null,
//             images: productDef.images.slice(),
//             badges: badges.slice(),
//             rating: productDef.rating,
//             reviews: productDef.reviews,
//             availability: productDef.availability,
//             brand: brandsData.then(r=>{return r.find((x) => x.slug === productDef.brand) || null}), //brandsData.find((x) => x.slug === productDef.brand) || null,
//             categories,
//             attributes,
//             customFields: {},
//         };
//     });


// export  {productList};
