import reviewsApi from '../../api/reviews';
import shopApi from '../../api/shop';
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from './quickviewActionTypes';

let cancelPreviousRequest = () => {};

export function quickviewOpenSuccess(product, reviewsCount) {
    return {
        type: QUICKVIEW_OPEN,
        product,
        reviewsCount
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(productSlug) {
    return (dispatch) => {
        cancelPreviousRequest();

        return new Promise((resolve) => {
            let canceled = false;
            // sending request to server, timeout is used as a stub
            const timer = setTimeout(() => {
                shopApi.getProductBySlug(productSlug).then((product) => {
                    if (canceled) {
                        return;
                    }
                    if (product) {
                        reviewsApi.getByProductId(product).then((response)=>{
                            ///alert(response.data.length);
                            let reviewsCount = response.data.length;
                            dispatch(quickviewOpenSuccess(product, reviewsCount));
                        })
    
                    }

                    resolve();
                });
            }, 350);

            cancelPreviousRequest = () => {
                canceled = true;
                clearTimeout(timer);
                resolve();
            };
        });
    };
}
