import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";

export const addressApi = {
  getHeader: () => {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${storageHelper.getToken()}`
    };
  },
  create: async data => {
    const response = await fetch(`${API_BASE_URL}/api/addresses`, {
      method: "POST",
      headers: addressApi.getHeader(),
      body: JSON.stringify(data)
    });
    return response.json();
  },
  update: async (data, id) => {
    //console.log(data)
    const response = await fetch(`${API_BASE_URL}api/addresses/${id}`, {
      method: "PATCH",
      headers: addressApi.getHeader(),
      body: JSON.stringify(data)
    });
    return response.ok;
  },
  get: async id => {
    const response = await fetch(`${API_BASE_URL}api/addresses/${id}`, {
      method: "GET",
      headers: addressApi.getHeader()
    });
    return response.json();
  },
  getByUser: async userId => {
    const response = await fetch(
      `${API_BASE_URL}api/addresses/fulldatabyuser/${userId}`,
      {
        method: "GET",
        headers: addressApi.getHeader()
      }
    );
    return response.json();
  },
  getPrincipal: async userId => {
    const response = await fetch(
      `${API_BASE_URL}api/addresses/fulldatabyuser/${userId}?filter[where][and][0][isPrincipal]=true`,
      {
        method: "GET",
        headers: addressApi.getHeader()
      }
    );
    return response.json();
  },
  lazyDelete: async data => {
    const response = await fetch(`${API_BASE_URL}api/addresses/${data.id}`, {
      method: "PATCH",
      headers: addressApi.getHeader(),
      body: JSON.stringify(data)
    });
    return response.ok;
  }
};

export default addressApi;
