/* eslint-disable no-unused-vars */
// react
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// third-party
import { Helmet } from "react-helmet-async";
import { Link, useParams, useHistory } from "react-router-dom";
import "animate.css";
import Swal from "sweetalert2";
// application
import PageHeader from "../shared/PageHeader";
//Helpers
import { storageHelper } from "../../helpers/localStorage";
import { toast } from "react-toastify";

// data stubs
import theme from "../../data/theme";
import resetsApi from "../../api/resets";
import sellResetsApi from "../../api/sellResets";

function ResetPage() {
  const { key } = useParams();
  let history = useHistory();
  const [isValidUser, setisValidUser] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [resetList, setResets] = React.useState([]);
  const [reset, setReset] = React.useState();
  const [userAuth, setuserAuth] = React.useState({});
  const [solicitudes, setSolicitudes] = React.useState([]);
  const [plan, setPlan] = React.useState();

  useEffect(() => {
    const authData = storageHelper.getUserData();
    setuserAuth(authData);

    // if (!authData.token) {
    //   toast.error(`Inicie sesión para tener acceso a los resets`);
    //   history.push("/");
    //   return;
    // }
    getAllResets();
  }, []);

  const breadcrumb = [
    { title: "Inicio", url: "/" },
    { title: "Reset & Firmwares", url: "" },
  ];

  const getAllResets = async () => {
    resetsApi.getAll().then((response) => {
      if (response.error) {
      } else {
        //console.log(response);
        setResets(response);
      }
    });
  };

  const getUserPedidosResets = async () => {
    sellResetsApi.getAll().then((response) => {
      setLoading(false);

      if (response.length > 0) {
        //console.log(response.filter((x) => x.buyer.id === userAuth.id));
        setSolicitudes(response.filter((x) => x.buyer.id === userAuth.id));
      }
    });
  };
  const SaveSellReset = (code, data, price) => {
    console.log(price);
    let dataSell = {
      date: new Date(),
      transactionCode: code,
      userId: userAuth.id,
      resetId: data.id,
      key: "",
      hid: "",
      descargable: false,
      precio: price,
    };
    //console.log(dataSell);
    sellResetsApi.create(dataSell).then((response) => {
      if (response.error) {
        toast.info(response.error.message);
      } else {
        toast.info("la solicitud ha sido enviada");
        getUserPedidosResets();
      }
      setLoading(false);
    });
  };

  const selectPlan = async (item, index) => {
    const { value: formValues } = await Swal.fire({
      title: "Seleccionar Plan",
      html:'<div class="container row">'+
      '<div class="col-lg-6"><div class="card"> <div class="card-header bg-warning text-white p-2 text-center"> <b>Uso único</b></div>' + 
      '<div class="card-body p-2 text-justify"><b><small class=\'text-justify\'>Precio: $'+ item.price + '</small> '+ 
      '</b> <Link to="/site/contact-us-alt"> <input class="mt-2 form-control" type="radio" value=2000.00 id="payUnico" name="pay"/></Link></div></div></div>' + 
      '<div class="col-lg-6"><div class="card"> <div class="card-header bg-danger text-white p-2 text-center"> <b>Uso permanente</b></div>' + 
      '<div class="card-body p-2 text-justify"><b><small class=\'text-justify\'>Precio: $'+ item.price * 10.00 + '</small> '+ 
      '</b> <Link to="/site/contact-us-alt"> <input class="mt-2 form-control" type="radio" value=20000.00 id="payPermanente" name="pay"/></i></Link></div></div></div>' + 
      '</div>',
      confirmButtonText: "Continuar",
      focusConfirm: false,
      preConfirm: () => {
          if(document.getElementById('payUnico').checked)
            return  document.getElementById('payUnico').value;
          else return document.getElementById('payPermanente').value;
      },
    });
    if (formValues) {
      setPlan(formValues);
      var btn = document.getElementById("selectPlan" + index);
      btn.innerHTML = formValues;
      if(document.getElementById('payUnico').checked)
        btn.className = "btn btn-warning btn-xs btn-block"
      else
      btn.className = "btn btn-danger btn-xs btn-block"
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Reset & Firmwares — ${theme.name}`}</title>
      </Helmet>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <PageHeader header="Reset & Firmware" breadcrumb={breadcrumb} />
        </div>
      </div>

      <div className="container">
        <div className="block">
          <div className="">
            <div className="row">
              <div className="card-table col-md col-sm-12">
                <h5>EPSON</h5>
                <table className="table table-striped table-hover table-info table-responsive">
                  <thead>
                    {
                      <tr>
                        {/* <th>Id</th> */}
                        <th>Equipo</th>
                        {/* <th>Marca</th> */}
                        <th>Modelo</th>
                        <th>Archivo</th>
                        <th>Precio</th>
                        <th></th>
                      </tr>
                    }
                    {resetList
                      .filter((x) => x.brand.name === "EPSON")
                      .map((r, index) => {
                        return (
                          <tr key={index}>
                            {/* <th>{r.id}</th> */}
                            <th>{r.device.name}</th>
                            {/* <th>{r.brand.name}</th> */}
                            <th>{r.model}</th>
                            <th>{r.typeFile}</th>
                            <th>
                              {/* <small> <select id={"price" + index} className='form-control'>
                                  <option value={r.price}>Un Uso $2000.00</option>
                                  <option value={r.price * 10.00}>Permanente $20000.00</option>
                                </select></small> */}
                              <button id={"selectPlan" + index}
                                className="btn btn-xs btn-primary"
                                onClick={() => selectPlan(r, index)}
                              >
                                Seleccionar Plan
                              </button> 
                            </th>
                            <th>
                              <button
                                className="btn btn-xs btn-primary"
                                onClick={() => {
                                  if (!userAuth.token) {
                                    toast.error(
                                      `Inicie sesión para tener acceso a los resets`
                                    );
                                    return;
                                  }

                                  var precio = parseFloat(document.getElementById("selectPlan" + index).innerText);
                                  if(!precio) return;
                                  Swal.fire({
                                    title:
                                      r.typeFile.toUpperCase() +
                                      " para " +
                                      r.device.name +
                                      " " +
                                      r.brand.name +
                                      " " +
                                      r.model +
                                      ". Precio: $" + precio,
                                    confirmButtonColor: "#3085d6",
                                    confirmButtonText: "Aceptar",
                                    input: "text",
                                    icon: "info",
                                    inputLabel:
                                      "2- Introduzca el ID de la Transacción realizada",
                                    html:
                                      "<span></span><span>" +
                                      '1- Realice su pago a la siguiente cuenta electronica: <b></d>0674-8340-0030-9714</b> <span/><img id="preview" src="/images/qr.png" /><br/>',
                                    showCancelButton: true,
                                    confirmButtonText: "Enviar",
                                    showLoaderOnConfirm: true,
                                    inputValidator: (transferCode) => {
                                      // Si el valor es válido, debes regresar undefined. Si no, una cadena
                                      if (!transferCode) {
                                        return "Introduzca el Código de Transacción";
                                      } else {
                                        return undefined;
                                      }
                                    },
                                    footer:
                                      "<p>Le atenderemos tan pronto sea posible. gracias</p>",
                                    showClass: {
                                      popup:
                                        "animate__animated animate__bounceIn",
                                    },
                                    hideClass: {
                                      popup:
                                        "animate__animated animate__fadeOutUp",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      SaveSellReset(
                                        result.value,
                                        r, precio
                                      );
                                    }
                                  });
                                }}
                              >
                                <span>Comprar</span>
                              </button>
                            </th>
                          </tr>
                        );
                      })}
                  </thead>
                </table>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card">
                  <div className="card-header bg-danger text-white p-2 text-center">
                    <b>
                      <span className="">
                        <i class="fa fa-warning" aria-hidden="true"></i> !!!
                        IMPORTANTE !!!
                      </span>
                    </b>
                  </div>
                  <div className="card-body p-2 text-justify">
                    <span className="text-danger text-justify">
                      <b>
                        Asegúrese de introducir correctamente los datos
                        solicitados por Offix para la posterior activación en su
                        equipo. Offix no se hace responsable por los errores
                        introducidos. Por favor lea los{" "}
                        <a className="text-primary" href="site/terms">
                          Términos y Condiciones
                        </a>{" "}
                        en el apartado Reset y Firmware
                      </b>
                    </span>
                  </div>
                </div>
                <div className="p-3"></div>
                <div className="card">
                  <div className="card-header bg-info text-white p-2 text-center">
                    <b>INFORMACION</b>
                  </div>
                  <div className="card-body p-2 text-justify">
                    <b>
                      <small className="text-justify">
                        Si el firmware o reset que usted necesita no se
                        encuentra en la lista, por favor comuníquese con
                        nosotros y le ayudamos.{" "}
                      </small>
                    </b>
                    {/* <button className='btn btn-link btn-sm'><i className='fa fa-envelope'></i></button> */}
                    <Link to="/site/contact-us-alt">
                      <i className="fa fa-envelope"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPage;
