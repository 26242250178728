// react
import React from 'react';
// third-party
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar'
// data stubs
import theme from '../../data/theme';

function Header(props) {
    const { layout, userData } = props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle pl-5 pr-5">
                <div className="site-header__logo">
                    {/* <Link to="/"><LogoSvg /></Link> */}
                    <Link to="/"><img alt="logo" src={Logo} /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        Atención al cliente
                    </div>
                    <div className="site-header__phone-number">
                        {theme.contacts.phone}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar /> 
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} userData={userData} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
