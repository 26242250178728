import { prepareCategory, walkTree } from '../database/categories';
import {categoriesApi} from "../../api/categories";


export async function getCategoriesAll(){
    const response = await categoriesApi.getAll();
    if(response)
        return response.data;
    else return [{}]
}

 let categoriesDef = getCategoriesAll();
// console.log(categoriesDef)

export function getCategories(options = {}) {
    return categoriesDef.then((response)=>{
        //console.log(response)
        const [categoriesTreeData, categoriesListData] = walkTree(response);
        //console.log(categoriesTreeData);

        var data =  Promise.resolve(
            categoriesTreeData.map((x) => prepareCategory(x, options.depth)),
        );
        //console.log(data);
        return data;
    })
}

export function getCategoryBySlug(slug, options = {}) {
    //console.log(slug)
    return categoriesDef.then((response)=>{
        console.log(response)
        const [categoriesTreeData, categoriesListData] = walkTree(response);
        console.log(categoriesListData);
        
        const category = categoriesListData.find((x) => x.slug === slug);
         console.log(category);
        var data =  category ? Promise.resolve(prepareCategory(category, options.depth)) : Promise.reject();
        // console.log(data)
        return data;
    });
}
