import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};
export const securityApi = {
  signup: async (data, uri) => {
    //console.log(`${API_BASE_URL}${uri}`)
    const response = await fetch(`${API_BASE_URL}${uri}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  signin: async (data) => {
    const response = await fetch(`${API_BASE_URL}api/security/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  requestCode: async (email) => {
    const response = await fetch(
      `${API_BASE_URL}api/security/reset-password/init`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(email),
      }
    );
    return response.json();
  },
  resetPassword: async (data) => {
    const response = await fetch(
      `${API_BASE_URL}api/security/reset-password/finish`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    return response.json();
  },
  activateUser: async (key) => {
    const response = await fetch(
      `${API_BASE_URL}api/security/activate/user/${key}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    return response.json();
  },
  isValidToken:async()=>{
    let token =  storageHelper.getToken() || "";
    const response = await fetch(
      `${API_BASE_URL}api/security/checkexpiration?token=${token}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    // console.log(response);
    return response.json();
  }
};

export default securityApi;
