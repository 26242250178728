/* eslint-disable no-unused-vars */
// react
import React from "react";
import { useHistory } from "react-router-dom";

// third-party
import { Helmet } from "react-helmet-async";

import { Formik, Form, Field, Select, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'animate.css';
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

//storage
import { storageHelper } from "../../helpers/localStorage";
// data stubs
import theme from "../../data/theme";
//Api
import { validateEmail } from "../../helpers/email";
import countriesApi from "../../api/countries";
import statesApi from "../../api/states";
import citiesApi from "../../api/cities";
import addressApi from "../../api/address";


export default function AccountPageCreateAddress() {
  const [loading, setLoading] = React.useState(false);
  const [userAuth, setuserAuth] = React.useState({});
  const [country, setCountry] = React.useState([]);
  const [states, setState] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [isPrincipal, setIsPrincipal] = React.useState(false);
  const [initValues, setInitialValues] = React.useState({});

  let history = useHistory();
  // const swall = withReactContent(Swal)

  React.useEffect(() => {
    if (!storageHelper.isAuthenticated()) {
      toast("Ud necesita iniciar sesión para realizar una solicitud de servicios", { type: 'error' });
      history.push("/");
      return;
    }
    const authData = storageHelper.getUserData();
    setuserAuth(authData);
  }, [history]);

  const loadCountries = () => {
    setState([]);
    setCities([]);
    if (country.length <= 0) {
      countriesApi.getAll().then((response) => {
        setCountry(response);
      });
    }
  };
  const loadStates = (countryId) => {
    setState([]);
    setCities([]);
    if (states.length <= 0) {
      statesApi.getByCountry(countryId).then((response) => {
        setState(response);
      });
    }
  };

  const loadCities = (stateId) => {
    setCities([]);
    if (cities.length <= 0) {
      citiesApi.getByState(stateId).then((response) => {
        setCities(response);
      });
    }
  };

  const CreateAddress = (data) => {
    //Verificar si hay direcciones creadas para el user
    addressApi.getPrincipal(data.userId).then((r) => {
      if (r.error) {
        toast.info(r.error.message);
        setLoading(false);
      } else {
        //No hay direcciones, se establece la principal
        if (r.length <= 0) {
          toast.info(
            "Ud no tiene direcciones creadas. Se establecerá ésta como principal"
          );
          data.isPrincipal = true;
          //Crear la direccion
          setTimeout(() => {
            addressApi.create(data).then((response) => {
              if (response.error) {
                toast.info(response.error.message);
              } else {
                toast.info("Se Agregó una dirección");
              }
              setLoading(false);
            });
          }, 1000);
        }
        //Hay direcciones y por ende ya una principal
        else {
          //si la que se crea sera principal
          if (data.isPrincipal) {
            //Buscar la principal guardada y cambiar estado
            //Aqui preguntar si desea cambiarla 
            Swal.fire({
              title: 'Dirección Princial',
              text: "¿Seguro que deseas cambiarla?",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sí, cambiar',
              cancelButtonText: 'Cancelar',
              showClass: {
                popup: 'animate__animated animate__bounceIn'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                r[0].isPrincipal = false;
                addressApi.update(r[0], r[0].id).then((response) => {
                  if (response["error"]) {
                    toast.warning(response["error"].message);
                    setLoading(false);
                  } else {
                    //Crear la direccion
                    setTimeout(() => {
                      addressApi.create(data).then((response) => {
                        if (response.error) {
                          toast.info(response.error.message);
                        } else {
                          toast.info("Se Agregó una dirección");
                        }
                        setLoading(false);
                      });
                    }, 1000);
                  }
                });
              } else {
                toast.info("No se creó la dirección");
                setLoading(false);
              }
            })
          } else {
            //Crear la direccion
            setTimeout(() => {
              addressApi.create(data).then((response) => {
                if (response.error) {
                  toast.info(response.error.message);
                } else {
                  toast.info("Se Agregó una dirección");
                }
                setLoading(false);
              });
            }, 1000);
          }
        }
      }
    });
  };

  //Formik Schemas
  const AddressSchema = Yup.object().shape({
    name: Yup.string().required("Complete este campo"),
    lastname: Yup.string().required("Complete este campo"),
    company: Yup.string(),
    street: Yup.string().required("Complete este campo"),
    apartament: Yup.string(),
    zip: Yup.string().required("Complete este campo"),
    email: Yup.string().email("Correo electrónico no válido"),
    phone: Yup.string(),
    website: Yup.string(),
    countryId: Yup.string().required("Seleccione un País"),
    stateId: Yup.string().required("Seleccione una Provincia/Estado"),
    cityId: Yup.string().required("Seleccione un Municipio/Ciudad"),
    isPrincipal: Yup.boolean(),
    userId: Yup.string(),
    enable: Yup.boolean(),
    createdDate: Yup.string(),
    updatedDate: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number()
  });

  return (
    <div className="card">
      <Helmet>
        <title>{`Crear Dirección — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <div className="form-row">
          <div className="col-md-5 col-lx-5">
            <h5>Crear Dirección</h5>
          </div>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-10 col-xl-10">
            <Formik
              initialValues={{
                name: "",
                lastname: "",
                company: " ",
                street: "",
                apartament: "",
                zip: "",
                email: "",
                phone: "",
                website: "",
                isPrincipal: false,
                countryId: "",
                cityId: "",
                stateId: "",
                userId: userAuth.id,
                enable: true,
                createdDate: new Date(),
                updatedDate: new Date(),
                latitude: 20.385742463415305, //Offix Office
                longitude: -76.64653609662014,
              }}
              validationSchema={AddressSchema}
              onSubmit={(values, { resetForm }) => {
                values.userId = userAuth.id;
                values.createdDate = new Date();
                values.updatedDate = new Date();
                values.enable = true;
                values.latitude = 20.385742463415305;
                values.longitude = -76.64653609662014;
                //console.log(values);
                setLoading(true);
                CreateAddress(values, { resetForm });
              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                dirty,
                onChange,
              }) => (
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12 mt-0 mb-3">
                      <div className="custom-control custom-switch ml-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch2"
                          onChange={(e) => {
                            setFieldValue(
                              "isPrincipal",
                              e.currentTarget.checked
                            );
                          }}
                        />
                        <label className="custom-control-label" for="customSwitch2">
                          Establecer como dirección principal
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-first-name">
                        Nombre del receptor
                      </label>
                      <Field
                        name="name"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-danger small">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-last-name">Apellidos</label>
                      <Field
                        name="lastname"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.lastname && touched.lastname ? (
                        <div className="text-danger small">
                          {errors.lastname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-company-name">
                      Nombre de la Empresa{" "}
                      <span className="text-muted">(Opcional)</span>
                    </label>
                    <Field
                      name="company"
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-7">
                      <label htmlFor="checkout-street-address">Calle</label>
                      <Field
                        name="street"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.street && touched.street ? (
                        <div className="text-danger small">{errors.street}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-5">
                      <label htmlFor="checkout-address">
                        Apartmento, suite, etc.{" "}
                        <span className="text-muted"></span>
                      </label>
                      <Field
                        name="apartament"
                        placeholder=""
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="countryId">País</label>
                      <Field
                        as="select"
                        name="country"
                        id="countryId"
                        className="form-control"
                        onClick={loadCountries}
                        onChange={(e) => {
                          setFieldValue("countryId", e.target.value);
                          loadStates(e.target.value);
                        }}
                      >
                        <option value={""}> - Seleccionar - </option>
                        {country.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.countryId && touched.countryId ? (
                        <div className="text-danger small">
                          {errors.countryId}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="">Teléfono</label>
                      <Field name="phone" className="form-control" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">
                        Provincia/Estado
                      </label>
                      <Field
                        as="select"
                        name="state"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("stateId", e.target.value);
                          loadCities(e.target.value);
                        }}
                      >
                        <option value={""} selected>
                          {" "}
                          - Seleccionar -{" "}
                        </option>
                        {states.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.stateId && touched.stateId ? (
                        <div className="text-danger small">
                          {errors.stateId}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="checkout-street-address">
                        Código Postal
                      </label>
                      <Field name="zip" className="form-control" />
                      {errors.zip && touched.zip ? (
                        <div className="text-danger small">{errors.zip}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">
                        Municipio/Ciudad
                      </label>
                      <Field
                        as="select"
                        name="city"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("cityId", e.target.value);
                        }}
                      >
                        <option value={""}> - Seleccionar - </option>
                        {cities.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.cityId && touched.cityId ? (
                        <div className="text-danger small">{errors.cityId}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="checkout-street-address">Email</label>
                      <Field
                        name="email"
                        className="form-control"
                        // validate={validateEmail}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small">{errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">º Latitud</label>
                      <Field name="latitude" disabled type="number" className="form-control"></Field>
                    </div>
                    
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-street-address">
                          º Longitud
                        </label>
                        <Field name="longitude" disabled type="number" className="form-control"></Field>
                      </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-company-name">
                      Website <span className="text-muted"></span>
                    </label>
                    <Field
                      name="website"
                      placeholder="www.miwebsite.com"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mt-3 mb-0">
                    {!loading && (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-success"
                      >
                        Guardar
                      </button>
                    )}
                    {loading && (
                      <button className="btn btn-primary" disabled>
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                        Espere...
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
