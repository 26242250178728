// eslint-disable-next-line import/no-anonymous-default-export
export default [
    // {
    //     title: '€ Euro',
    //     currency: {
    //         code: 'EUR',
    //         symbol: '€',
    //         name: 'Euro',
    //     },
    // },
    // {
    //     title: '£ Pound Sterling',
    //     currency: {
    //         code: 'GBP',
    //         symbol: '£',
    //         name: 'Pound Sterling',
    //     },
    // },
    {
        title: 'USD',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'Dólar US'
        },
    },
    {
        title: 'MLC',
        currency: {
            code: 'MLC',
            symbol: '$',
            name: 'Moneda Convertible'
        },
    },
    {
        title: 'CUP',
        currency: {
            code: 'CUP',
            symbol: '$',
            name: 'Peso Cubano'
        },
    },
    // {
    //     title: '₽ Russian Ruble',
    //     currency: {
    //         code: 'RUB',
    //         symbol: '₽',
    //         name: 'Russian Ruble',
    //     },
    // },
];
