export const storageHelper = {
  setUserData:  (data) => {
    try {
      window.localStorage.setItem("token", data.token);
      window.localStorage.setItem("email", data.email);
      window.localStorage.setItem("id", data.id);
      window.localStorage.setItem("role", data.role);
      window.localStorage.setItem("name", data.name);
      window.localStorage.setItem("avatar", data.avatar);
    } catch (e) {
      return null;
    }
  },
  getUserData: () => {
    try {
      var value = {
        token: window.localStorage.getItem("token"),
        email: window.localStorage.getItem("email"),
        id: window.localStorage.getItem("id"),
        role: window.localStorage.getItem("role"),
        name: window.localStorage.getItem("name"),
        avatar: window.localStorage.getItem("avatar"),
      };
      if (value !== null) {
        return value;
      }
    } catch (e) {
      return null;
    }
  },
  getUserRole: async() => {
    try {
      var value = {
        role: window.localStorage.getItem("role"),
      };
      if (value !== null) {
        return value;
      }
    } catch (e) {
      return null;
    }
  },
  setToken: (token) => {
    try {
      window.localStorage.setItem("token", token);
    } catch (e) {
      return null;
    }
  },
  getToken: () => {
    try {
      const value = window.localStorage.getItem("token");
      if (value !== null) {
        return value;
      }
    } catch (e) {
      return null;
    }
  },
  setTempData: (data) => {
    try {
      window.localStorage.setItem("tempData", data);
    } catch (e) {
      return null;
    }
  },
  getTempData: () => {
    try {
      const value = window.localStorage.getItem("tempData");
      if (value !== null) {
        return value;
      }
    } catch (e) {
      return null;
    }
  },
  releaseTempData: () => {
    window.localStorage.removeItem("tempData");
  },
  logout: () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("uid");
    window.localStorage.removeItem("role");
    window.localStorage.removeItem("roleId");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("avatar");
  },
  isAuthenticated: () => {
    return (storageHelper.getToken() === undefined || storageHelper.getToken() === null)? false : true;
  },
};
