/* eslint-disable no-unused-vars */
// react
import React, {useRef}from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useHistory } from 'react-router-dom';
import PageHeader from '../shared/PageHeader';
import theme from '../../data/theme';
import { Formik, Form, Field, Select, useFormikContext, FormikContext, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'animate.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
//storage
import { storageHelper } from "../../helpers/localStorage";
import BlockLoader from '../blocks/BlockLoader';
import serviceApi from '../../api/service';
import brandsApi from '../../api/brands';
import serviceOrderApi from '../../api/serviceOrder';
import SecuencialApi from '../../api/secuencial';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorHandler from '../../helpers/errorHandler';
import addressApi from '../../api/address';

function ServiceOrderPage() {
    const { serviceid } = useParams();
    let history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [userAuth, setuserAuth] = React.useState({});
    const [service, setService] = React.useState({});
    const [isDomicilio, setDomicilio] = React.useState(false);
    const [brands, setBrands] = React.useState([]);
    const [viewListAddress, setViewListAddreess] = React.useState(false);
    const [AddressList, setAddressList] = React.useState(false);
    const [address, setAddress] = React.useState("");

    const formikProps = useFormikContext()

    const someFunctionWithLogic = (addr) => {
      formikProps.setFieldValue("address", addr)
    }

    const isLogged = () =>{
      const authData = storageHelper.getUserData();
      if (!authData.token) {
          window.location.replace("/");
          return;
      }
      else return authData;
  }

  const ChangeAddress = (address) =>{
    let addr = address.street + " #" + address.apartament + ", " + address.city.name + ", " + address.state.name;
    setAddress(addr);
    setViewListAddreess(false);
   // someFunctionWithLogic("algo");

    // formikRef.setFieldValue("address", "UN VALOR");
    // this.setState({isVisibleList: false});
    // this.setState({myAddresses: address});
  }

    React.useEffect(() => {
        setLoading(false)
        if (!storageHelper.isAuthenticated()) {
            toast("Ud necesita iniciar sesión para realizar una solicitud de servicios", { type: 'error' });
            history.push("/services/list");
            return;
        }
        const authData = storageHelper.getUserData();
        setuserAuth(authData);
        serviceApi.get(serviceid).then((response) => {
            if (!response.error) {
                setService(response);
            }
        })
        loadBrands();
        setLoading(false)
    }, [serviceid, history]);

    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: 'Servicios', url: '/services/list' },
        { title: service.name, url: '' },
    ];

    const loadBrands = async ()=>{
      let brandList = await brandsApi.getAll();
      setBrands(brandList.data);
    }

    const getPrincipalAddress=(event)=>{
      var usr = isLogged();
      //console.log(usr);
      addressApi.getByUser(usr.id).then((resp) => {
        if (resp.error) {
          toast.error(ErrorHandler.parseError(resp.error));
          return;
        }
        else if (resp.length <= 0) {
          toast.error("No se encuentran direcciones de envío configuradas");
          return;
        } else {
          //let addr = resp.filter((x) => x.isPrincipal);
          if (resp.length > 0) {
            setAddressList(resp);
            console.log(resp);
            // this.setState({ myAddresses: addr[0] });
            // this.setState({ myAddressList: resp });
            //console.log(this.state.myAddressList);
          } else {
            setAddressList([]);
            // this.setState({ myAddresses: {} });
            // this.setState({ myAddressList: [] });
          }
        }
      });
      //console.log(this.state.myAddresses);
  }

    if (loading) {
        return <BlockLoader />;
    }

    const redirect =(orderId)=> {
      return <Redirect to={`/checkout/success/${orderId}`}  />;
    }

    const NewOrderSchema = Yup.object().shape({
        brandId: Yup.string().required("Complete este campo"),
        model: Yup.string(),
        failure: Yup.string().required("Complete este campo"),
        address: Yup.string(),
        houseservice: Yup.boolean(),
        model: Yup.string(),
    });

    const CreateOrder = (data, { resetForm }) => {
        setLoading(true);

        SecuencialApi.getByDocument("SERVICIO");

        Swal.fire({
          title: 'Finalizar Pedido',
          text: "¿Deseas finalizar la solicitud?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
          backdrop: false,
          showClass: {
            popup: 'animate__animated animate__bounceIn'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
              SecuencialApi.generarSecuencial("SERVICIO").then((secResponse)=>{
                // let myAddress = "";
                if(data.address)  
                   //myAddress = data.address.street + " #" + data.address.apartament + ", " + data.address.city.name + ", " + data.address.state.name;
                  data.orderNum = secResponse;
                  data.address = address;
                  setLoading(true);

                  serviceOrderApi.create(data).then((response) => {
                    if (response.error) {
                      toast.error(ErrorHandler.parseError(response.error));
                      setLoading(true);
                    } else {
                      localStorage.setItem(
                        "service",
                        JSON.stringify(data)
                      );
                      toast.info("Solicitud Completada");
                      setLoading(false);
                      setAddress("");
                      // this.redirect(response.id);
                    }
                  }); 
              });
          } else {
              setLoading(false);
          }
        })
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Servicios — ${theme.name}`}</title>
        </Helmet>
        <PageHeader header="Solicitud de Servicio" breadcrumb={breadcrumb} />

        <div className="container card">
          <div className="card-header">
            <div className="form-row">
              <div className="col-md-5 col-lx-5">
                <h5>Crear Solicitud </h5>
              </div>
            </div>
          </div>
          <div className="card-divider" />
          <div className="card-body">
            <div className="row no-gutters">
              <div className="col-12 col-lg-12 col-xl-12">
                <Formik
                  //innerRef={formikRef}
                  initialValues={{
                    status: "open",
                    customerId: userAuth.id,
                    enable: true,
                    createdDate: new Date(),
                    updatedDate: new Date(),
                    address: "",
                    orderNum: "",
                    fecha:  new Date(),
                    brandId: "",
                    failure: "",
                    houseservice: false,
                    serviceId: "",
                    model:"",
                }}
                  validationSchema={NewOrderSchema}
                  onSubmit={(values, {resetForm}) => {
                    values.businessId = "7ff786dd-c4de-43e7-94c9-8afb4bbb91de";
                    values.customerId = userAuth.id;
                    values.createdDate = new Date();
                    values.updatedDate = new Date();
                    values.fecha = new Date();
                    values.enable = true;
                    values.serviceId = serviceid;
                    values.houseservice = false;
                    values.orderNum= "123";
                    values.status = "PENDIENTE";
                    values.address = address
                    //console.log(values);
                    setLoading(true);
                    CreateOrder(values, { resetForm });
                  }}
                >
                  {({
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                    dirty,
                    onChange,
                  }) => (
                    <form >
                      <div className="form-row">
                        <div className="form-group col-md-8 mt-0 mb-3">
                          <div className="form-group col-md-10">
                            <label htmlFor="servicio" ><b >Servicio: <span className='text-danger'>{service.name}</span></b></label>
                          </div>
                          <div className="form-group col-md-10">
                            <label htmlFor="marca">Marca</label>
                            <Field
                              name="brandId"
                              placeholder="Seleccione la marca "
                              className="form-control"
                              as="select"
                              id="brandId"
                              onChange={(e) => {
                                setFieldValue("brandId", e.target.value);
                              }}
                            >
                              <option value={""}> - Seleccionar - </option>
                              {brands != undefined && brands.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                            {errors.brandId && touched.brandId ? (
                              <div className="text-danger small">
                                {errors.brandId}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-10">
                            <label htmlFor="model">Modelo</label>
                            <Field
                              name="model"
                              placeholder="Introduzca el modelo"
                              className="form-control"
                              id="model"
                            />
                            {errors.model && touched.model ? (
                              <div className="text-danger small">
                                {errors.model}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-10">
                            <label htmlFor="failure">
                              Descripción General
                            </label>
                            <Field
                              as="textarea"
                              name="failure"
                              placeholder=""
                              className="form-control"
                            />
                            {errors.failure && touched.failure ? (
                              <div className="text-danger small">
                                {errors.failure}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-12 mt-0 mb-3">
                            <div className="custom-control custom-switch ml-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch2"
                                onChange={(e) => {
                                  setFieldValue(
                                    "houseservice",
                                    e.currentTarget.checked
                                  );
                                  setDomicilio(e.currentTarget.checked);
                                  setAddress("");
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch2"
                              >
                                Servicio a Domicilio
                              </label>
                            </div>
                          </div>
                          {isDomicilio && (
                            <div className="form-row">
                              <div className="form-group col-md-10">
                                <label htmlFor="address">Dirección </label>
                                <label htmlFor="address"></label>
                                <button
                                    className='btn btn-info btn-xs ml-3'
                                  type="button"
                                  onClick={() => {
                                    //setLoading(true);
                                    setViewListAddreess(true);
                                    getPrincipalAddress();
                                  }}
                                >
                                  Seleccionar
                                </button>
                                <Field
                                  //innerRef={AddressRef}
                                  as="textarea"
                                  name="address"
                                  placeholder="Seleccione una de sus direcciones"
                                  className="form-control"
                                  disabled
                                  value = {address}
                                />
                                {errors.address && touched.address ? (
                                  <div className="text-danger small">
                                    {errors.address}
                                  </div>
                                ) : null}
                              </div>
                              <div className='col-md-2'>
                                
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4 col-xl-4">
                          {/* LISTA DE DIRECCIONES PARA SELECCIONAR */}
                          {viewListAddress && AddressList &&
                                AddressList.map(
                                  (address, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className=" card address-card address-card--featured"
                                      >
                                        <div className="address-card__body">
                                          <div className="address-card__name ">{`${
                                            address && address.name
                                          } ${
                                            address && address.lastname
                                          }`}</div>
                                          <div className="address-card__row">
                                            {address.country &&
                                              address.country.name}
                                            <br />
                                            {address.state &&
                                              address.state.name}
                                            ,{" "}
                                            {address.city && address.city.name},{" "}
                                            {address.state && address.zip}
                                            <br />
                                            {address && address.street}, {" #"}
                                            {address && address.apartament}
                                          </div>
                                          <button
                                            type="button"
                                            //disabled={!this.state.acceptTerms}
                                            className="btn btn-primary mt-2 btn-block"
                                            onClick={() =>
                                              ChangeAddress(address)
                                            }
                                          >
                                            Seleccionar
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                        </div>
                      </div>
                      <div className="form-group mt-3 mb-0">
                        {!loading && (
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-success"
                          >
                            Enviar Solicitud
                          </button>
                        )}
                        {loading && (
                          <button className="btn btn-primary" disabled>
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                            Espere...
                          </button>
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default ServiceOrderPage;