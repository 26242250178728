import { prepareCategory, walkTree } from "../database/categories";
import AbstractFilterBuilder from "./abstract";
// eslint-disable-next-line no-unused-vars
import productsData from "../database/products";
// eslint-disable-next-line no-unused-vars
import { categoryHasProducts } from "../utils";
import {categoriesApi} from "../../api/categories";

export default class CategoryFilterBuilder extends AbstractFilterBuilder {
  value = null;
  items = [];
   categoriesListData = [];
   categoriesTreeData = [];

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  test(product) {
    if (this.value === null) {
      return true;
    }

    var data = product.categories.reduce((acc, category) => (acc || category.slug === this.value), false);
    return data;
    //return true;
  }

  async getCategoriesAll() {
    const response = await categoriesApi.getAll();
    // console.log(response.data)
    if(response) return [] = walkTree(response.data);    
    return [];
  }




  makeItems(products, value) {
    let cat = this.getCategoriesAll();
    return cat.then((response)=>{
        if(response.length <= 0) return;
        // console.log(response)

        this.categoriesListData =  response[0];
        this.categoriesTreeData =  response[1];

        this.value = value || null;
        
        // console.log(this.categoriesListData);

        const category = this.categoriesListData.find((x) => x.slug === value);
        // console.log(category)
        const categoryHasProductsFn = (x) => categoryHasProducts(x, products);

        // const categoryHasProductsFn = () => true;
    
        if (category) {
          this.items = [prepareCategory(category, 1)].map((x) => ({
            ...x,
            children: x.children.filter(categoryHasProductsFn),
          }));
        } else {
          this.items = this.categoriesTreeData
            .map((x) => prepareCategory(x))
            .filter(categoryHasProductsFn);
        }    
    })
  }

  // eslint-disable-next-line class-methods-use-this
  calc() {}

  build() {
    return {
      type: "category",
      slug: this.slug,
      name: this.name,
      items: this.items,
      value: this.value,
    };
  }
}
