// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageTerms() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Terms And Conditions', url: '' },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Terms And Conditions — ${theme.name}`}</title>
        </Helmet>

        <PageHeader breadcrumb={breadcrumb} />

        <div className="block">
          <div className="container">
            <div className="document">
              <div className="document__header">
                <h1 className="document__title">Términos y Condiciones</h1>
                <div className="document__subtitle">
                  Este acuerdo fue modificado la última vez el 30 de Abril de
                  2023.
                </div>
              </div>
              <div className="document__content typography text-justify">
                <p>
                  Es requisito necesario para la adquisición de los productos
                  que se ofrecen en este sitio, que lea y acepte los siguientes
                  Términos y Condiciones que a continuación se redactan. El uso
                  de nuestros servicios así como la compra de nuestros productos
                  implicará que usted ha leído y aceptado los Términos y
                  Condiciones de Uso en el presente documento.
                </p>
                <h2>Definiciones</h2>
                <p>
                  <strong>Usuario y Contraseña:</strong> Para adquirir un
                  producto, será necesario el registro por parte del usuario,
                  con ingreso de datos personales fidedignos y definición de una
                  contraseña. El usuario puede elegir y cambiar la clave para su
                  acceso de administración de la cuenta en cualquier momento, en
                  caso de que se haya registrado y que sea necesario para la
                  compra de alguno de nuestros productos. Offix no asume la
                  responsabilidad en caso de que entregue dicha clave a
                  terceros.
                </p>
                <p>
                  <strong>Compras:</strong> Todas las compras y transacciones
                  que se lleven a cabo por medio de este sitio web, están
                  sujetas a un proceso de confirmación y verificación, el cual
                  podría incluir la verificación del stock y disponibilidad de
                  producto, validación de la forma de pago, validación de la
                  factura (en caso de existir) y el cumplimiento de las
                  condiciones requeridas por el medio de pago seleccionado. En
                  algunos casos puede que se requiera una verificación por medio
                  de correo electrónico. Los precios de los productos ofrecidos
                  en esta Tienda Online es válido solamente en las compras
                  realizadas en este sitio web.
                </p>
                <p>
                  <strong>Reembolso y Grarantía:</strong> En el caso de
                  productos que sean mercancías irrevocables no-tangibles, no
                  realizamos reembolsos después de que se envíe el producto,
                  usted tiene la responsabilidad de entender antes de comprarlo.
                  Le pedimos que lea cuidadosamente antes de comprarlo. Hacemos
                  solamente excepciones con esta regla cuando la descripción no
                  se ajusta al producto. Hay algunos productos que pudieran
                  tener garantía y posibilidad de reembolso pero este será
                  especificado al comprar el producto. En tales casos la
                  garantía solo cubrirá fallas de fábrica y sólo se hará
                  efectiva cuando el producto se haya usado correctamente. La
                  garantía no cubre averías o daños ocasionados por uso
                  indebido. Los términos de la garantía están asociados a fallas
                  de fabricación y funcionamiento en condiciones normales de los
                  productos y sólo se harán efectivos estos términos si el
                  equipo ha sido usado correctamente. Esto incluye:
                  <ol>
                    <li>
                      De acuerdo a las especificaciones técnicas indicadas para
                      cada producto.
                    </li>
                    <li>
                      En condiciones ambientales acorde con las especificaciones
                      indicadas por el fabricante.
                    </li>
                    <li>
                      En uso específico para la función con que fue diseñado de
                      fábrica.
                    </li>
                    <li>
                      En condiciones de operación eléctricas acorde con las
                      especificaciones y tolerancias indicadas.
                    </li>
                  </ol>
                </p>
                <p>
                  <strong>Resets y Firmwares: </strong>
                  La descarga y utilización de Resets o Firmwares para
                  impresoras o plotters está sujeto a su pago y verificación
                  previos. En los archivos descargados se incluyen los pasos a
                  seguir para el proceso de activación que incluyen:
                  <ol>
                    <li>Instalar y ejecutar Software</li>
                    <li>
                      <strong className="text-danger">
                        Copiar y enviar a Offix información de Id del Hardware
                        (Hardware Id) que proporciona el software.
                      </strong>
                      <br />
                      En caso de no tener opción de copiar y pegar el código
                      generado, deberá introducirlo manualmente para su
                      posterior envio. Sea muy cuidadoso{" "}
                      <b>
                        Offix no se hace responsable por los errores
                        introducidos por el usuario.
                      </b>{" "}
                      La introducción de errores puede provocar error en la
                      genereación de la llave de activación y por ende el
                      producto no podrá ser activado y para evitar fraudes no
                      será generado un nuevo Código, a menos que se realice un
                      nuevo pago. (Esto pede estar sujeto a excepciones siempre
                      y cuando Offix lo estime conveniente)
                      <br />
                      Como medida de seguridad para evitar complicaciones por
                      caracteres confusos usted puede hacer una captura de pantalla
                      del software con la información del Hardware ID generado y enviarla a <strong className='text-primary'>bussinesoffix@gmail.com</strong> 
                    </li>
                    <li>
                      Esperar la respuesta de Offix con el código ( llave ) de
                      activación. <b>Tenga en cuenta que este paso puede demorar hasta 24 horas en hacerce efectivo. </b>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default SitePageTerms;
