//export const API_URL = 'http://192.168.42.54:3000';
//export const API_BASE_URL = 'http://localhost:3000/';
///export const BASE_URL = 'http://192.168.42.54:4200/';

// export const API_URL = 'http://192.168.43.2:3000';
export const API_BASE_URL = 'https://api.offixcorp.com/';
// export const BASE_URL = 'http://192.168.43.2:4200';

  export const PROFILE_PATH = 'upload/profile/';
  export const PROFILE_DEFAULT = '../../assets/img/profile/';
  export const DOC_PATH = 'upload/w-9/';


export const PATTERN = {
    /* At least 6 characters in length, lowercase and numbers */
	
    username : "^[a-z0-9_-]{6,20}$",
    /*
      At least 8 characters in length
      Lowercase letters
      Uppercase letters
      Numbers
      Special characters 
    */
    password: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}",
    phone: "^((\\+91-?)|0)?[0-9]{10}$", 
    email: "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
  };


  export const Languages = [
    // {languageId: 1, code:"EN", name: "English"},
    {languageId: 2, code:"ES", name: "Spanish"},
    {languageId: 3, code:"FR", name: "French"},
    
    {languageId: 4, code:"RU", name: "Russian"},
    {languageId: 5, code:"CN", name: "Mandarin"},
    {languageId: 6, code:"HT", name: "Haitian"},
    {languageId: 7, code:"CR", name: "Croatian"},
    {languageId: 8, code:"RO", name: "Romanian"},
    {languageId: 9, code:"DE", name: "German"},
    {languageId: 10, code:"TR", name: "Turkish"},
    {languageId: 11, code:"PT", name: "Portuguesse"}
  ];
  
  export const CertificationType =[
      {id: 1, type: "Federal"},
      {id: 2, type: "State"}
  ];

  export const AtivityType =[
	{id: 1, type: "REMOTE"},
	{id: 2, type: "FACE-TO-FACE"}
];

export const OrderTarget =[
	{id: 1, name: "Court Reporter Only"},
	{id: 2, name: "Other Providers"},
	{id: 3, name: "Both Other Providers and Court Reporter"}
];

  export const StarRate =[
	{id: 5, value: "Rate: " + 5 + '\u2605', text: ' \u2605 \u2605 \u2605 \u2605 \u2605'},
	{id: 4, value: "Rate: " + 4 + '\u2605', text: ' \u2605 \u2605 \u2605 \u2605'},
	{id: 3, value: "Rate: " + 3 + '\u2605', text: ' \u2605 \u2605 \u2605'},
	{id: 2, value: "Rate: " + 2 + '\u2605', text: ' \u2605 \u2605'},
	{id: 1, value: "Rate: " + 1 + '\u2605', text: ' \u2605'},
];
  
 export const Days = [
            { id: 1, day: "Monday" },
            { id: 2, day: "Tuesday" },
            { id: 3, day: "Wednesday" },
            { id: 4, day: "Thursday" },
            { id: 5, day: "Friday" },
            { id: 6, day: "Saturday" },
            { id: 7, day: "Sunday" }
        ];
	export const COLORS_APP = {
               Buttons: "#160650",
               Bar: "#023002",
               HeaderText: "#ffffff",
               HeaderBackground: "#160650",
               Text: "#000000",
               Icons: "#160650",
               Danger: "#860404",
               Warning: "#B6E23E",
               Success: "#087A08",
               Morado1: "#572364",
               Morado2: "#8a36d2",
               Morado3: "#4b0081",
               Morado4: "#551a8b",
               Morado5: "#321350",
               Star5: "#B6E23E",
               Star4: "#087A08",
               Star3: "#860404",
               Star2: "#860404",
               Star1: "#860404"
           }; 	