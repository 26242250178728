/* eslint-disable no-unused-vars */
import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";

const headers = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const filesApi = {
  upload: async (data) => {
    const formData = new FormData();

    const response = await fetch(`${API_BASE_URL}api/files`, {
      method: 'POST',
      headers: headers,
      body: data,
    });
    return response.json();
  },
  readFiles: async (path) => {
    const response = await fetch(`${API_BASE_URL}api/files`, {
      method: 'GET',
      headers: headers
    });
    return response.json();
  },

  downloadFile: async (filename) => {
    const response = await fetch(`${API_BASE_URL}api/files/${filename}`, {
      method: 'GET',
      headers: headers,
    });
    // return response.json();
    // return axios.get(
    //   `${API_BASE_URL}api/files/${filename}`,
    //   {
    //     responseType: "blob",
    //     headers: headers,
        
    //   }
    // );
  },
  

};



export default filesApi;
