/* eslint-disable no-unused-vars */
// react
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useHistory } from 'react-router-dom';

function ResetOrderPage() {

}

export default ResetOrderPage;