import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";
import axios from "axios";
import { parse } from "query-string";
//import consecutivesData from "../fake-server/database/consecutives";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
  Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const SecuencialApi = {
  create: async (data) => {
    const response = await fetch(`${API_BASE_URL}api/consecutives`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  update: async (data, id) => {
    const response = await fetch(`${API_BASE_URL}api/consecutives/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.ok;
  },
  get: async (id) => {
    const response = await fetch(`${API_BASE_URL}api/consecutives/${id}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
  getByDocument: async (document) => {
    const response = await fetch(
      `${API_BASE_URL}api/consecutives/findbydocument/${document}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    return response.json();
  },
  getAll: async () => {
    let response;
    try {
      response = await axios.get(
        `${API_BASE_URL}api/consecutives?filter[where][enable]=true`
      );
    } catch (e) {}
    // if success return value
    return response;
  },
  lazyDelete: async (item) => {
    const response = await fetch(`${API_BASE_URL}api/consecutives/${item.id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(item),
    });
    return response;
  },
  delete: async (id) => {
    const response = await axios.delete(
      `${API_BASE_URL}api/consecutives/${id}`,
      {
        headers: headers,
      }
    );
    return response;
  },
  FormatSecuencial(Secuencial) {
    var prefix = "00";
    var secuencialRet = "";
    switch (Secuencial.length) {
      case 1:
        secuencialRet = prefix + Secuencial;
        break;
      case 2:
        prefix = "0";
        secuencialRet = prefix + Secuencial;
        break;
      case 3:
        prefix = "";
        secuencialRet = prefix + Secuencial;
        break;
    }
    return secuencialRet;
  },
  generarSecuencial: async (document) => {
    let sec = await SecuencialApi.getByDocument(document);
    var prefijo = sec[0].prefix;
    var consecutivo =  sec[0].sequential;
    var numero = prefijo + '-' + SecuencialApi.FormatSecuencial(consecutivo + "");
    let obj = sec[0];
    obj.sequential = obj.sequential + 1;
    let resp = await SecuencialApi.update(obj, obj.id);
    if(resp){
      return numero ;
    }else{
      return "INVALIDO" ;
    }
  },
};

export  default SecuencialApi;
