import CategoryFilterBuilder from '../filters/category';
import CheckFilterBuilder from '../filters/check';
import productsData from '../database/products';
import RadioFilterBuilder from '../filters/range';
import ColorFilterBuilder from '../filters/color';
import RangeFilterBuilder from '../filters/price';

function getProducts(shift, options) {
    return productsData().then(response=>{
        const limit = options.limit || 8;
        let products = [...response.slice(shift), ...response.slice(0, shift)];
    
        // if (options.category === 'herramientas-electricas') {
        //     products = products.slice().reverse();
        // } else if (options.category === 'electronicos') {
        //     products = [...products.slice(8), ...products.slice(0, 8)];
        // } else if (options.category === 'instrumentos') {
        //     products = [...products.slice(8), ...products.slice(0, 8)].reverse();
        // }
    
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(products.slice(0, limit));
            }, 500);
        });
    })
    
}

export function getProductBySlug(slug) {
    return productsData().then(response=>{
        const product = response.find((x) => x.slug === slug);
        return product ? Promise.resolve(product) : Promise.reject();
    })   
}

export function getRelatedProducts(slug, options = {}) {
    return productsData().then(response=>{
    const limit = options.limit || 8;
    return Promise.resolve(response.slice(0, limit));
})
}

export function getProductsList(options = {}, filterValues = {}) {
    return productsData().then(response=>{
        const filters = [
            new CategoryFilterBuilder('category', 'Categorias'),
            new RangeFilterBuilder('price', 'Precio'),
            new CheckFilterBuilder('brand', 'Marca'),
            new RadioFilterBuilder('discount', 'Descuento'),
            new ColorFilterBuilder('color', 'Color'),
        ];
    
        let items = response.slice(0);
        filters.forEach((filter) => filter.makeItems(items, filterValues[filter.slug]));
    
        // Calculate items count for filter values.
        filters.forEach((filter) => filter.calc(filters));
    
        // Apply filters to products list.
        items = items.filter((product) => filters.reduce((mr, filter) => mr && filter.test(product), true));
        //console.log(items)

        if(filterValues["category"] !== undefined){
            //console.log(filterValues["category"])
            items.forEach(item => {
                // console.log(item.categories)
                //return;
                item.categories.forEach(cat=>{
                    
                    if(cat === filterValues["category"])
                    {console.log(item)}
                })
            });
        }
        
        const page = options.page || 1;
        const limit = options.limit || 24;
        const sort = options.sort || 'default';
        const total = items.length;
        const pages = Math.ceil(total / limit);
        const from = (page - 1) * limit + 1;
        const to = Math.max(Math.min(page * limit, total), from);
        
        
        items = items.sort((a, b) => {
            if (['name_asc', 'name_desc'].includes(sort)) {
                if (a.name === b.name) {
                    return 0;
                }
    
                return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
            }
    
            return 0;
        });
    
        const start = (page - 1) * limit;
        const end = start + limit;
    
        items = items.slice(start, end);
    
        var prom = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    page,
                    limit,
                    sort,
                    total,
                    pages,
                    from,
                    to,
                    items,
                    filters: filters.map((x) => x.build()),
                });
            }, 350);
        });
        // console.log(prom)
        return prom;
    })
}

export function getFeaturedProducts(options = {}) {
    return getProducts(0, options);
}

export function getLatestProducts(options = {}) {
    return getProducts(0, options);
}

export function getTopRatedProducts(options = {}) {
    return getProducts(0, options);
}

export function getDiscountedProducts(options = {}) {
    return getProducts(3, options);
}

export function getPopularProducts(options = {}) {
    return getProducts(6, options);
}

export function getSuggestions(query, options) {
  return productsData().then(response => {
    const limit = options.limit || 5;
    return Promise.resolve(
        response.filter(x => x.name.toLowerCase().includes(query.toLowerCase())).slice(0, limit)
    );
  });
}
