/* eslint-disable no-unused-vars */
// react
import React from 'react';


// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useHistory } from 'react-router-dom';
import "animate.css";
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// application
import PageHeader from '../shared/PageHeader';
import { API_BASE_URL } from "../../environment/environment";

// data stubs
import theme from '../../data/theme';
import securityApi from '../../api/security';
import profileApi from '../../api/profile';
import filesApi from '../../api/files';

function SitePageDocumentos() {
    const { key } = useParams();
    let history = useHistory();
    const [isValidUser, setisValidUser] = React.useState(true);
    const [files, setFileList] = React.useState();

    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: 'Documentos', url: '' },
    ];

    React.useEffect(()=>{
         filesApi.readFiles('').then((r)=>{
            setFileList(r);
            console.log(r)
         });
    }, []);
    
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Descargar documentos — ${theme.name}`}</title>
        </Helmet>

        <PageHeader header="Documentos oficiales" breadcrumb={breadcrumb} />

        <div className="block">
          <div className="pl-5 pr-5">
            <div className="row">
              <div className="card-table col-md-8 col-sm-12">
                <Tabs
                  defaultActiveKey="profile"
                  id="tab_docs"
                  className="mb-3 m-2"
                >
                  <Tab
                    eventKey="documentos_legales"
                    title="Documentos Legales"
                    className="m-2 p-2"
                  >
                    <table className="table table-bordered table-hover">
                      <tr>
                        <th>Archivo</th>
                      </tr>
                      <tr>
                        <td>
                          <span>Certifco BANCARIO</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Certifco BANCARIO.jpg`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-download"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Registro Mercantil OFFIX SURL</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Registro Mercantil OFFIX SURL.jpg`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-download"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Licencia Comercial CUP</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Licencia Comercial CUP.jpg`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-download"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Licencia Comercial MLC</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Licencia Comercial MLC.jpg`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-download"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Certifico Juridico Offix</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Certifico Juridico Offix.pdf`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-file-pdf"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      
                      <tr>
                        <td>
                          <span>Escritura pública de constitución SURL Unipersonal. Firmado</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Escritura_publica_de_constitucion_SURL_Unipersonal._Euclides_Offix_Firmado_Firmado.pdf`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-file-pdf"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Estatutos OFFIX Firmado</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Estatutos_OFFIX_Firmado.pdf`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-file-pdf"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                     <tr>
                        <td>
                          <span>Identificación fiscal única RC05</span>
                          <a
                            className="float-right "
                            href={`${API_BASE_URL}api/files/Identificación fiscal única -RC05.pdf`}
                          >
                            <span className="float-right h5 text-primary">
                              <i className="fa fa-file-pdf"></i>
                            </span>{" "}
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Tab>
                  <Tab
                    eventKey="contratos_plotter"
                    title="Contratos Servicios Técnicos"
                    className="m-2 p-2"
                  >
                    <table className="table table-bordered table-hover">
                      <tr>
                        <th>Archivo</th>
                      </tr>
                      {/*<tr>
                        <td>
                           <span>Contrato OFFIX SURL Servicios Tecnicos (Plotter, Impresoras, PC y OTROS 2024 USD y CUP)</span> 
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos 2024 (Impresoras, PC, otros) CUP.docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos 2024 (Impresoras, PC, otros) CUP.pdf`}
                          >
                            {
                              <span className="float-right text-primary">
                                <i className="fa fa-file-pdf medium h5"></i>
                              </span>
                            }
                            
                          </a>
                        </td>
                      </tr>
                      */}
                      {/* <tr>
                        <td>
                          <span> Contrato OFFIX SURL Servicios Tecnicos (Impresoras, PC y OTROS 2024 USD y CUP)</span>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos (Impresoras, PC y OTROS 2024 USD y CUP).docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos (Impresoras, PC y OTROS 2024 USD y CUP).pdf`}
                          >
                            {
                              <span className="float-right text-primary">
                                <i className="fa fa-file-pdf medium h5"></i>
                              </span>
                            }
                            
                          </a>
                        </td>
                      </tr> */}
                      
                      <tr>
                        <td>
                          <span> Contrato OFFIX SURL Servicios Tecnicos 2024 (Impresoras, PC, otros) CUP </span>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos 2024 (Impresoras, PC, otros) CUP.docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                          </a>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato OFFIX SURL Servicios Tecnicos 2024 (Impresoras, PC, otros) CUP.pdf`}
                          >
                            {
                              <span className="float-right text-primary">
                                <i className="fa fa-file-pdf medium h5"></i>
                              </span>
                            }
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span> Suplemento 2024 </span>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Suplemento2024.docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <span> Ficha del cliente OFFIX SURL </span>
                        <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/FICHA DEL CLIENTE OFFIX SURL.docx`}
                          >
                            {
                              <span className="float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>  
                        </td>
                      </tr>
                    </table>
                  </Tab>
                  <Tab
                    eventKey="compra-venta"
                    title="Contratos Para Compraventa USD Y CUP"
                    className="m-2 p-2"
                  >
                    <table className="table table-bordered table-hover">
                      <tr>
                        <th>Archivo</th>
                      </tr>
                      <tr>
                        <td>
                        <span> Contrato de Compraventa OFFIX SURL 2024 Clientes  USD y CUP </span>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato de Compraventa OFFIX SURL 2024 Clientes USD y CUP.docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                          </a>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato de Compraventa OFFIX SURL 2024 Clientes USD y CUP.pdf`}
                          >
                            {
                              <span className="float-right text-primary">
                                <i className="fa fa-file-pdf medium h5"></i>
                              </span>
                            }
                            
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <span> Contrato de Compraventa OFFIX SURL CUP 2024 </span>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato de Compraventa OFFIX SURL CUP 2024.docx`}
                          >
                            {
                              <span className="ml-3 float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>
                          <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/Contrato de Compraventa OFFIX SURL CUP 2024.pdf`}
                          >
                            {
                              <span className="float-right text-primary">
                                <i className="fa fa-file-pdf medium h5"></i>
                              </span>
                            }
                            
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <span> Ficha del cliente OFFIX SURL </span>
                        <a
                            className="float-right"
                            href={`${API_BASE_URL}api/files/FICHA DEL CLIENTE OFFIX SURL.docx`}
                          >
                            {
                              <span className="float-right text-danger">
                                <i className="fa fa-file-word medium h5"></i>
                              </span>
                            }
                            
                          </a>  
                        </td>
                      </tr>
                    </table>
                  </Tab>
                </Tabs>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <div className="card-header bg-danger text-white p-2 text-center">
                    <b>
                      <span className="">
                        <i class="fa fa-warning" aria-hidden="true"></i> !!!
                        IMPORTANTE !!!
                      </span>
                    </b>
                  </div>
                  <div className="card-body p-2 text-justify">
                    <span className="text-danger text-justify">
                      <b>
                        Estimado cliente asegurese de elegir la proforma de
                        contrato que más se ajuste a su entidad, tomando como
                        referencia la lista emitida por el MEP para las empresas
                        que puedan hacer pagos en CL, ya que la mayoría de los
                        insumos que se ofertan para compraventa se
                        comercializarán en CL y otra parte en CUP.
                        <a href='https://www.mep.gob.cu/es/entidades-que-aplican-la-resolucion-1152020-anotada-y-concordada-del-mep'> Visite la página oficial del MEP</a> 
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default SitePageDocumentos;
