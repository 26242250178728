/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        title: 'Power Tools',
        url: '/shop',
        products: 572,
        image: 'images/categories/category-1.jpg',
        subcategories: [
            { title: 'Screwdrivers', url: '/shop' },
            { title: 'Milling Cutters', url: '/shop' },
            { title: 'Sanding Machines', url: '/shop' },
            { title: 'Wrenches', url: '/shop' },
            { title: 'Drills', url: '/shop' },
        ],
    },
    {
        title: 'Hand Tools',
        url: '/shop',
        products: 134,
        image: 'images/categories/category-2.jpg',
        subcategories: [
            { title: 'Screwdrivers', url: '/shop' },
            { title: 'Hammers', url: '/shop' },
            { title: 'Spanners', url: '/shop' },
            { title: 'Handsaws', url: '/shop' },
            { title: 'Paint Tools', url: '/shop' },
        ],
    },
    {
        title: 'Machine Tools',
        url: '/shop',
        products: 301,
        image: 'images/categories/category-4.jpg',
        subcategories: [
            { title: 'Lathes', url: '/shop' },
            { title: 'Milling Machines', url: '/shop' },
            { title: 'Grinding Machines', url: '/shop' },
            { title: 'CNC Machines', url: '/shop' },
            { title: 'Sharpening Machines', url: '/shop' },
        ],
    },
    {
        title: 'Power Machinery',
        url: '/shop',
        products: 79,
        image: 'images/categories/category-3.jpg',
        subcategories: [
            { title: 'Generators', url: '/shop' },
            { title: 'Compressors', url: '/shop' },
            { title: 'Winches', url: '/shop' },
            { title: 'Plasma Cutting', url: '/shop' },
            { title: 'Electric Motors', url: '/shop' },
        ],
    },
    {
        title: 'Measurement',
        url: '/shop',
        products: 366,
        image: 'images/categories/category-5.jpg',
        subcategories: [
            { title: 'Tape Measure', url: '/shop' },
            { title: 'Theodolites', url: '/shop' },
            { title: 'Thermal Imagers', url: '/shop' },
            { title: 'Calipers', url: '/shop' },
            { title: 'Levels', url: '/shop' },
        ],
    },
    {
        title: 'Clothes and PPE',
        url: '/shop',
        products: 81,
        image: 'images/categories/category-6.jpg',
        subcategories: [
            { title: 'Winter Workwear', url: '/shop' },
            { title: 'Summer Workwear', url: '/shop' },
            { title: 'Helmets', url: '/shop' },
            { title: 'Belts and Bags', url: '/shop' },
            { title: 'Work Shoes', url: '/shop' },
        ],
    },
];
