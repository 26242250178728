/* eslint-disable no-unused-vars */
// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useHistory } from 'react-router-dom';
import "animate.css";
import Swal from "sweetalert2";

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import securityApi from '../../api/security';
import profileApi from '../../api/profile';

function SitePageUserActivated() {
    const { key } = useParams();
    let history = useHistory();
    const [isValidUser, setisValidUser] = React.useState(true);

    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: 'Activacion de Cuenta', url: '' },
    ];

    React.useEffect(() => {
        if (key) {
            securityApi.activateUser(key).then((r) => {
                if (!r.error) {
                    Swal.fire({
                        title: `Bienvenido`,
                        text: `La cuenta ${r.email} se ha activado correctamente`,
                        icon: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ir al Inicio",
                        cancelButtonText: "Ver Informacón de activación",
                        showClass: {
                            popup: "animate__animated animate__bounceIn",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/");
                        }
                    })                   
                }else {
                    setisValidUser(false)
                }
            })
        }
    }, [key, history])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`Activación de Cuenta — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        {isValidUser && (<div className="document__header">
                            <h1 className="document__title">Activación de Cuenta Existosa</h1>
                            <p>
                                Si usted puede ver esta página es porque su usuario se activó correctamente. Para acceder a nuestros servicios y tener una mejor experiencia incie sesión.
                            </p>
                            <Link className='btn btn-success btn-lg' to={'/'}>Ir al Inicio</Link>
                        </div>)}
                        {!isValidUser && (<div className="document__header">
                            <h1 className="document__title">Activación de Cuenta Fallida</h1>
                            <p>
                                El usuario que está intentando activar no existe en nustro sistema. Por favor contacte con nuestro servicio de atención al cliente.
                            </p>
                        </div>)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageUserActivated;
