/* eslint-disable no-unused-vars */
// react
import React from "react";
import { useHistory } from "react-router-dom";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import theme from "../../data/theme";

//Api
import addressApi from "../../api/address";
import profileApi from "../../api/profile";
//Helpers
import { storageHelper } from "../../helpers/localStorage";
import filesApi from "../../api/files";
import { AuthContext } from "../../helpers/auth";

import { API_BASE_URL } from "../../environment/environment";

export default function AccountPageDashboard(props) {
  const [userAuth, setuserAuth] = React.useState({});
  const [dataAddresses, setDataAddresses] = React.useState([]);
  const [address, setAddress] = React.useState({empty: true});
  const [loading, setLoading] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");

  let history = useHistory();
  const avatarSelect = React.useRef();
  const { Login } = React.useContext(AuthContext);

  React.useEffect(() => {
    const authData = storageHelper.getUserData();
    setuserAuth(authData);
    if (!authData.token) {
      history.push("/");
      return;
    }
    addressApi.getByUser(authData.id).then((response) => {
      if (response.error) {
        toast.info(response.error.message);
      } else {
        if (response.length > 0) {
          let add = response.find((x) => x.isPrincipal);
          setAddress(add);
        }
      }
    });
    profileApi.getByUserId(authData.id).then((response) => {
      if (response["error"]) {
        toast.warning(response["error"].message);
        setLoading(false);
        return;
      } else if (response.length <= 0) {
        history.push("/account/profile");
        //setImgUrl("images/avatars/default-avatar.png");
      } else {
        if (!response[0].photo || response[0].photo === "undefined") {
          setImgUrl("images/avatars/default-avatar.png");
        } else {
          setImgUrl(`${API_BASE_URL}api/files/${response[0].photo}`);
        }
      }
    });
  }, [history]);

  //const address = dataAddresses[0];
  //   const orders = allOrders.slice(0, 3).map((order) => (
  //     <tr key={order.id}>
  //       <td>
  //         <Link to="/account/orders/5">#{order.id}</Link>
  //       </td>
  //       <td>{order.date}</td>
  //       <td>{order.status}</td>
  //       <td>{order.total}</td>
  //     </tr>
  //   ));

  const avatarClick = (event) => {
    avatarSelect.current.click();
  };

  const handleChange = (event) => {
    setLoading(true);
    //Preparing form
    const fileInput = document.getElementById("avatarInput");
    const formData = new FormData();
    const extension = fileInput.files[0].name.split(".", 2, 1)[1];
    var date = Date.now().toString();
    const newFilename = `${userAuth.id}[${date}].${extension}`;
    formData.append("avatarInput", fileInput.files[0], newFilename);
    //Uploading
    filesApi.upload(formData).then((response) => {
      if (response.error) {
        toast.info(response.error.message);
      } else {
        if (response.files.length > 0) {
          //UpdateProfile Photo Database
          setTimeout(() => {
            UpdateProfile({ photo: response.files[0].originalname });
            setImgUrl(
              `${API_BASE_URL}api/files/${response.files[0].originalname}`
            );
            toast.info("Su avartar se ha actualizado");
            setLoading(false);
          }, 1000);
        }
      }
    });
  };

  const UpdateProfile = (data) => {
    //Verificar si tiene perfil creado
    let profileId = "";
    profileApi.getByUserId(userAuth.id).then((response) => {
      if (response["error"]) {
        toast.warning(response["error"].message);
        setLoading(false);
        return;
      } else if (response.length > 0) {
        profileId = response[0].id;
        if (profileId !== "") {
          //Update Profile
          profileApi.update(data, profileId).then((response) => {
            if (response["error"]) {
              toast.warning(response["error"].message);
              setLoading(false);
            } else {
              var sessData = storageHelper.getUserData();
              sessData.avatar = data.photo;
              storageHelper.setUserData(sessData);
              Login(sessData);
              setLoading(false);
              //toast.info("Su perfil ha sido actualizado correctamente");
            }
          });
        }
      }
    });
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>{`Mi Cuenta — ${theme.name}`}</title>
      </Helmet>

      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          {!loading && (
            <div className="profile-card__avatar">
              <form id="uploadForm">
                <img
                  style={{ width: 100, height: 90, cursor: "pointer" }}
                  id="avatar"
                  name="avatar"
                  src={imgUrl}
                  alt=""
                  onClick={() => {
                    avatarClick(avatarSelect);
                  }}
                />

                <input
                  type="file"
                  accept="image/*"
                  name="avatarInput"
                  id="avatarInput"
                  hidden
                  ref={avatarSelect}
                  onChange={handleChange}
                />
              </form>
            </div>
          )}
          {loading && (
            <div className="profile-card__avatar">
              <div className="text-center">
                <div className="spinner-grow" role="status">
                  {/* <span className="sr-only">Loading...</span> */}
                </div>
              </div>
            </div>
          )}
          <div className="profile-card__name">{userAuth.name}</div>
          <div className="profile-card__email">{userAuth.email}</div>
          <div className="profile-card__edit">
            <Link to="profile" className="btn btn-secondary btn-sm">
              Editar Perfil
            </Link>
          </div>
        </div>
      </div>
      {!address.empty  && 
        <div className="dashboard__address card address-card address-card--featured">
          {address.isPrincipal && (
            <div className="address-card__badge">Principal</div>
          )}
          <div className="address-card__body">
            <div className="address-card__name ">{`${address.name} ${address.lastname}`}</div>
            <div className="address-card__row">
              {address.country.name}
              <br />
              {address.state.name}, {address.city.name}, {address.zip}
              <br />
              {address.street}, {" #"}
              {address.apartament}
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Teléfono</div>
              <div className="address-card__row-content">{address.phone}</div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Email </div>
              <div className="address-card__row-content">{address.email}</div>
            </div>
            {/* <div className="address-card__footer">
              <Link to={`/account/addresses/${address.id}`}>Editar</Link>
              &nbsp;&nbsp;
              <Link to="/">Quitar</Link>
            </div> */}
          </div>
        </div>
      }
      {address.empty  && 
        <div className="dashboard__address card address-card address-card--featured">
          <div className="address-card__body">
            <div className="address-card__name text-center">{`No hay direcciones para mostrar`}</div>
          </div>
        </div>
      }
      {/* <div className="dashboard__orders card">
        <div className="card-header">
          <h5>Recent Orders</h5>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>{orders}</tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
}
