// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import StroykaSlick from "../shared/StroykaSlick";

// data stubs
import theme from "../../data/theme";

const slickSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 379,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function SitePageAboutUs() {
  return (
    <div className="block about-us">
      <Helmet>
        <title>{`Acerca de Offix — ${theme.name}`}</title>
      </Helmet>

      <div
        className="about-us__image"
        style={{ backgroundImage: 'url("images/aboutus.jpg")' }}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-10">
            <div className="about-us__body">
              <h1 className="about-us__title">Somos Offix</h1>
              <div className="about-us__text typography text-justify">
                <p>
                  Una sociedad dedicada a ofrecer productos y
                  servicios tecnológicos a la medida de tus necesidades.
                  Brindamos calidad y confianza desde 2018, de la mano de un
                  competente equipo de trabajo. Estamos Prestos a recibir a
                  nuestros clientes en Calle Martí No. 203 entre Figueredo y Lora, 
                  ciudad de Bayamo, capital de la provincia de Granma. Para la solución que buscas, no dudes en
                  acudir a nosotros, en cualquier momento durante las horas de
                  oficina, de lunes a sábado, entre las 9:00 A.M. y las 6:00
                  P.M.; o si lo prefieres contáctanos por: •
                  bussinesoffix@gmail.com • (+53) 23 425039 • (+53) 50926652
                </p>
              </div>
              <br></br>
              <br></br>
              <h1 className="about-us__title">Visión</h1>
              <div className="about-us__text typography text-justify">
                <p>
                  Posicionar un sello de confianza y liderazgo en el mercado,
                  ofreciendo en todo momento productos y servicios de la mayor
                  calidad, que garanticen las mejores experiencias a nuestros
                  clientes, para ser siempre Tu Primera Opción.
                </p>
              </div>
              <br></br>
              <br></br>
              <h1 className="about-us__title">Destacamos</h1>
              <div className="about-us__text typography text-justify">
                <p>
                  Offix marca la diferencia en Granma por ser un equipo
                  profesional listo para brindarte servicios de reparación de
                  plotters e impresoras de inyección o chorro de tinta, siempre
                  comprometidos con la eficiencia y rapidez que mereces.
                </p>
              </div>
              <br></br>
              <br></br>
              <h1 className="about-us__title">Preferimos</h1>
              <div className="about-us__text typography text-justify">
                <p>
                  Nuestra sociedad prefiere construir relaciones duraderas con
                  clientes conocedores y de buen gusto, capaces de distinguir
                  las mejores tecnologías e interesados en recibir servicios de
                  alto estándar.
                </p>
              </div>
              <br></br>
              <br></br>
              <h1 className="about-us__title">Ofertamos</h1>
              <div className="about-us__text typography text-justify">
                <p>
                  Offix te garantiza rapidez y seguridad en la Instalación, el
                  Mantenimiento y Reparación de ordenadores y equipos
                  periféricos, así como máquinas registradoras, fotocopiadoras y
                  Plotter. También Reparamos tus equipos de comunicaciones, y te
                  ofrecemos mayor comodidad a través de Ventas por correo
                  electrónico e internet. Para conectarte con tus metas
                  Comercializamos programas informáticos, ordenadores, equipos
                  periféricos y de telecomunicaciones, además de sus partes,
                  piezas y accesorios. Si lo que necesitas es ayuda para un
                  proyecto inmediato cuenta con nuestro servicio de Alquiler de
                  equipos de oficina.
                </p>
              </div>
              <div className="about-us__team">
                <h2 className="about-us__team-title">Conoce nuestro equipo</h2>
                <div className="about-us__team-subtitle text-muted">
                  ¿Te gustaría formar parte de nuestro equipo de trabajo?
                  <br />
                  <Link to="/site/contact-us-alt">Contáctanos</Link> y
                  consideraremos tus aptitudes.
                </div>
                <div className="about-us__teammates teammates">
                  <StroykaSlick {...slickSettings}>
                    <div className="teammates__item teammate">
                      <div className="teammate__avatar">
                        <img src="images/teammates/euclides.jpg" alt="" />
                      </div>
                      <div className="teammate__name">
                        Euclides Jiménez Arró
                      </div>
                      <div className="teammate__position text-muted">
                        Presidente
                      </div>
                    </div>
                    <div className="teammates__item teammate">
                      <div className="teammate__avatar">
                        <img src="images/teammates/teammate-3.jpg" alt="" />
                      </div>
                      <div className="teammate__name">José L. Figueredo</div>
                      <div className="teammate__position text-muted">
                        Económico
                      </div>
                    </div>
                    <div className="teammates__item teammate">
                      <div className="teammate__avatar">
                        <img src="images/teammates/ale.jpg" alt="" />
                      </div>
                      <div className="teammate__name">Alexandro Hernández M. </div>
                      <div className="teammate__position text-muted">
                         Desarrollador de Software
                      </div>
                    </div>
                    <div className="teammates__item teammate">
                      <div className="teammate__avatar">
                        <img src="images/teammates/maikel.jpg" alt="" />
                      </div>
                      <div className="teammate__name">Maikel A. Justo</div>
                      <div className="teammate__position text-muted">
                         Jefe Técnico 
                      </div>
                    </div>
                    <div className="teammates__item teammate">
                      <div className="teammate__avatar">
                        <img src="images/teammates/adilen.jpg" alt="" />
                      </div>
                      <div className="teammate__name">Adilén Sánchez</div>
                      <div className="teammate__position text-muted">
                         Comercial
                      </div>
                    </div>
                  </StroykaSlick>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SitePageAboutUs;
