/* eslint-disable no-unused-vars */
// react
import React from "react";
import { useHistory, useParams } from "react-router-dom";

// third-party
import { Helmet } from "react-helmet-async";

import { Formik, Form, Field, Select, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "animate.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//storage
import { storageHelper } from "../../helpers/localStorage";
// data stubs
import theme from "../../data/theme";
//Api
import { validateEmail } from "../../helpers/email";
import countriesApi from "../../api/countries";
import statesApi from "../../api/states";
import citiesApi from "../../api/cities";
import addressApi from "../../api/address";

export default function AccountPageCreateAddress() {
  const [loading, setLoading] = React.useState(false);
  const [userAuth, setuserAuth] = React.useState({});
  const [country, setCountry] = React.useState([]);
  const [states, setState] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [isPrincipal, setIsPrincipal] = React.useState(false);
  const [initValues, setInitialValues] = React.useState({});

  let history = useHistory();
  const swall = withReactContent(Swal);
  const { addressId } = useParams();
  const countryRef = React.useRef();
  const formikRef = React.useRef();

  React.useEffect(() => {
    const authData = storageHelper.getUserData();
    setuserAuth(authData);
    if (!authData.token) {
      history.push("/");
      return;
    }
    //Cargar paises
    countriesApi.getAll().then((response) => {
      setCountry(response);
    });
    statesApi.getAll().then((response) => {
      setState(response);
    });
    citiesApi.getAll().then((response) => {
      setCities(response);
    });

    //cargar direccion
    addressApi.get(addressId).then((r) => {
      setInitialValues(r);
    });
    
  }, [history, addressId, countryRef]);


  const loadStates = (countryId) => {
    setState([]);
    setCities([]);
    if (states.length <= 0) {
      statesApi.getByCountry(countryId).then((response) => {
        setState(response);
        formikRef.current.values.isPrincipal.checked = response.isPrincipal;
      });
    }
  };

  const loadCities = (stateId) => {
    setCities([]);
    if (cities.length <= 0) {
      citiesApi.getByState(stateId).then((response) => {
        setCities(response);
      });
    }
  };

  const UpdateAddress = (data) => { 
    setTimeout(() => {
      addressApi.update(data, data.id).then((response) => {
        if (response.error) {
          toast.info(response.error.message);
        } else {
          toast.info("Se Modificó la dirección");
        }
        setLoading(false);
      });
    }, 1000);
  };

  //Formik Schemas
  const AddressSchema = Yup.object().shape({
    name: Yup.string().required("Complete este campo"),
    lastname: Yup.string().required("Complete este campo"),
    company: Yup.string(),
    street: Yup.string().required("Complete este campo"),
    apartament: Yup.string(),
    zip: Yup.string().required("Complete este campo"),
    email: Yup.string().email("Correo electrónico no válido"),
    phone: Yup.string(),
    website: Yup.string(),
    countryId: Yup.string().required("Seleccione un País"),
    stateId: Yup.string().required("Seleccione una Provincia/Estado"),
    cityId: Yup.string().required("Seleccione un Municipio/Ciudad"),
    isPrincipal: Yup.boolean(),
    userId: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number()

  });

  return (
    <div className="card">
      <Helmet>
        <title>{`Modificar Dirección — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <div className="form-row">
          <div className="col-md-5 col-lx-5">
            <h5>Modificar Dirección</h5>
          </div>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-10 col-xl-10">
            <Formik
              innerRef={formikRef}
              initialValues={initValues}
              enableReinitialize={true}
              validationSchema={AddressSchema}
              onSubmit={(values, { resetForm }) => {
                setLoading(true);
                UpdateAddress(values, { resetForm });
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                dirty
              }) => (
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12 mt-0 mb-3">
                      <div className="custom-control custom-switch ml-2">
                        <Field
                          type="checkbox"
                          className="custom-control-input"
                           id="isPrincipal"
                          name="isPrincipal"
                          onChange={(e) => {
                            setFieldValue("isPrincipal", e.currentTarget.checked);
                          }}
                          disabled
                        />
                        <label className="custom-control-label" htmlFor="isPrincipal">
                          Establecer como dirección principal
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-first-name">Nombre del receptor</label>
                      <Field
                        name="name"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-danger small">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-last-name">Apellidos</label>
                      <Field
                        name="lastname"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.lastname && touched.lastname ? (
                        <div className="text-danger small">
                          {errors.lastname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-company-name">
                      Nombre de la Empresa{" "}
                      <span className="text-muted">(Opcional)</span>
                    </label>
                    <Field
                      name="company"
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-7">
                      <label htmlFor="checkout-street-address">Calle</label>
                      <Field
                        name="street"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.street && touched.street ? (
                        <div className="text-danger small">{errors.street}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-5">
                      <label htmlFor="checkout-address">
                        Apartmento, suite, etc.{" "}
                        <span className="text-muted"></span>
                      </label>
                      <Field
                        name="apartament"
                        placeholder=""
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="countryId">País</label>
                      <Field
                        as="select"
                        value={values.countryId}
                        name="country"
                        id="countryId"
                        className="form-control"
                        //onClick={loadCountries}
                        onChange={(e) => {
                          setFieldValue("countryId", e.target.value);
                          loadStates(e.target.value);
                        }}
                      >
                        <option value={""}> - Seleccionar - </option>
                        {country.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.countryId && touched.countryId ? (
                        <div className="text-danger small">
                          {errors.countryId}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="">Teléfono</label>
                      <Field name="phone" className="form-control" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">
                        Provincia/Estado
                      </label>
                      <Field
                        as="select"
                        value={values.stateId}
                        name="state"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("stateId", e.target.value);
                          loadCities(e.target.value);
                        }}
                      >
                        <option value={""}>
                          {" "}
                          - Seleccionar -{" "}
                        </option>
                        {states.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.stateId && touched.stateId ? (
                        <div className="text-danger small">
                          {errors.stateId}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="checkout-street-address">
                        Código Postal
                      </label>
                      <Field name="zip" className="form-control" />
                      {errors.zip && touched.zip ? (
                        <div className="text-danger small">{errors.zip}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">
                        Municipio/Ciudad
                      </label>
                      <Field
                        as="select"
                        value={values.cityId}
                        name="city"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("cityId", e.target.value);
                        }}
                      >
                        <option value={""}> - Seleccionar - </option>
                        {cities.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.cityId && touched.cityId ? (
                        <div className="text-danger small">{errors.cityId}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label htmlFor="checkout-street-address">Email</label>
                      <Field
                        name="email"
                        className="form-control"
                      // validate={validateEmail}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small">{errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-street-address">º Latitud</label>
                      <Field name="latitude" disabled type="number" className="form-control"></Field>
                    </div>
                    
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-street-address">
                          º Longitud
                        </label>
                        <Field name="longitude" disabled type="number" className="form-control"></Field>
                      </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-company-name">
                      Website <span className="text-muted"></span>
                    </label>
                    <Field
                      name="website"
                      placeholder="www.miwebsite.com"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mt-3 mb-0">
                    {!loading && (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-success"
                      >
                        Guardar
                      </button>
                    )}
                    {loading && (
                      <button className="btn btn-primary" disabled>
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                        Espere...
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
