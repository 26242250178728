// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner() {
    return (
        <div className="block block-banner">
            <div className="">
                <Link to="/reset/list" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: 'url("images/banners/banner-1.jpg")' }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("images/banners/banner-1-mobile.jpg")' }}
                    />
                    <div className="block-banner__title">
                        {/* Nuestros principales Servicios */}
                        <br className="block-banner__mobile-br" />
                        {/* Mantenimiento y Reparación de Impresoras / Plotters / Fotocopiadoras */}
                    </div>
                    <div className="block-banner__text">
                        {/* Solicite nuestros servicios a través del apartado de Servicios */}
                    </div>
                    {/* <div className="block-banner__button">
                        <span className="btn btn-sm btn-primary">Solicitar</span>
                    </div> */}
                </Link>
            </div>
        </div>
    );
}
