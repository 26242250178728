// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';
import { url } from '../../services/utils';
import BlockHeader from '../shared/BlockHeader';
import BlockLoader from '../blocks/BlockLoader';
import productsApi from '../../api/products';

function IndicatorCart(props) {

    const [loading, setLoading] = React.useState(false);
    const [counter, setCounter] = React.useState(false);

    const { cart, cartRemoveItem } = props;
    let dropdown;
    let totals;

    if (cart.extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                {/* <td><Currency value={extraLine.price} /></td> */}
            </tr>
        ));

        totals = (
            <React.Fragment>
                {/* <tr>
                    <th>Subtotal</th>
                    <td><Currency value={cart.subtotal} /></td>
                </tr> */}
                {extraLines}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img className="product-image__img" src={item.product.images[0]} alt="" />
                    </Link>
                </div>
            );
        }

        const cartRemove =async ()=>{
            setLoading(true)
            let prod = await productsApi.get(item.product.id);
            let newProd={
                stock: prod.stock + item.quantity,
            };
            var resp = await productsApi.updateStock(newProd, item.product.id);
            if(resp)
                await cartRemoveItem(item.id)
            setLoading(false)
        }


        return (
          <div key={item.id} className="dropcart__product">
            {image}
            <div className="dropcart__product-info">
            <div className="dropcart__product-meta">
                <span className="dropcart__product-quantity">
                  {item.quantity}
                </span>
                {" × "}
                <span className="dropcart__product-price">
                  {/* <Currency value={item.price} /> */}
                </span>
              </div>
              <div className="dropcart__product-name">
                <Link to={url.product(item.product)}>{item.product.name}</Link>
              </div>
              {options}
            </div>
            <button
              type="button"
              className='dropcart__product-remove btn btn-light btn-sm btn-svg-icon'
              onClick={() => {
                cartRemove(item);
              }}
            //   className={classNames(
            //     "dropcart__product-remove btn btn-light btn-sm btn-svg-icon",
            //     {
            //       "btn-loading": loading,
            //     }
            //   )}
            >
              <Cross10Svg />
            </button>
          </div>
        );
    });

    if (cart.quantity) {
            
        dropdown = (
            <div className="dropcart">
                {loading && <BlockLoader />}

                {!loading && <div className="dropcart__products-list">
                    {items}
                </div>}

                {!loading && <div className="dropcart__totals">
                    <table>
                        {/* <tbody>
                            {totals}
                            <tr>
                                <th>Total</th>
                                <td><Currency value={cart.total} /></td>
                            </tr>
                        </tbody> */}
                    </table>
                </div>}
                {!loading &&  <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart">Ver Carrito</Link>
                    <Link className="btn btn-primary" to="/shop/checkout">Checkout</Link>
                </div>}
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    Tu carrito está vacío!
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/shop/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
