// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
//import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import sellOrdersDetailsApi from '../../api/sellOrderDetails';
import sellOrdersApi from '../../api/sellOrders';
// import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
//storage
import { storageHelper } from "../../helpers/localStorage";

function ShopPageOrderSuccess(props) {
  const [loading, setLoading] = React.useState(false);
  const [userAuth, setuserAuth] = React.useState({});

  //check if logged
  
  const authData = storageHelper.getUserData();
    if (!authData.token) {
      window.location.replace("/");
    }
    
  var order = JSON.parse(localStorage.getItem("order"));
  //console.log(order)
  var details = JSON.parse(localStorage.getItem("orderdetails"));
  
    if(!order){
      window.location.replace("/shop/catalog");
    }

  

  const items =
    details &&
    details.map((item) => {
      const options = (item.options || []).map((option) => (
        <li className="order-list__options-item">
          <span className="order-list__options-label">
            {item.product.name}
            {": "}
          </span>
          <span className="order-list__options-value">{option.value}</span>
        </li>
      ));

      return (
        <tr>
          <td className="order-list__column-image">
            <div className="product-image">
              <Link
                to={url.product(item.product)}
                className="product-image__body"
              >
                <img
                  className="product-image__img"
                  src={item.product.images[0]}
                  alt=""
                />
              </Link>
            </div>
          </td>
          <td className="order-list__column-product">
            <Link to={url.product(item.product)}>{item.product.name}</Link>
            {/* {options.length > 0 && (
            <div className="order-list__options">
                  <ul className="order-list__options-list">{options}</ul>
            </div>
          )} */}
          </td>
        
          <td className="order-list__column-price" data-title="Price:">
            {/* <Currency value={item.price} /> */}
          </td>
          <td className="order-list__column-total">
            {/* <Currency value={item.total} /> */}
          </td>
          <td className="order-list__column-quantity" data-title="Qty:">
            {item.quantity}
          </td>
        </tr>
      );
    });

  const additionalLines = order && order.additionalLines.map((line) => (
    <tr>
      <th className="order-list__column-label" colSpan="4">
        {/* {line.label} */}
      </th>
      <td className="order-list__column-total">
        {/* <Currency value={line.total} /> */}
      </td>
    </tr>
  ));

  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order Success — ${theme.name}`}</title>
      </Helmet>

      <div className="container">
        <div className="order-success__body">
          <div className="order-success__header">
            <Check100Svg className="order-success__icon" />
            <h1 className="order-success__title">Muchas Gracias</h1>
            <div className="order-success__subtitle">
              Tu pedido ha sido recibido
            </div>
            <div className="order-success__actions">
              <Link to="/shop/catalog" className="btn btn-xs btn-secondary">
                Ir a la tienda
              </Link>
            </div>
          </div>

          <div className="order-success__meta">
            <ul className="order-success__meta-list">
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">
                  Número de Orden:
                </span>
                <span className="order-success__meta-value">{`#${order && order.noSellOrden}`}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Fecha:</span>
                <span className="order-success__meta-value">
                  {order && new Date(order.orderDate).toLocaleDateString()}
                </span>
              </li>
              {/* <li className="order-success__meta-item">
                <span className="order-success__meta-title">Total:</span>
                <span className="order-success__meta-value">
                  <Currency value={order && order.total} />
                </span>
              </li> */}
              {/* <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Metodo de Pago:</span>
                                <span className="order-success__meta-value">{order.paymentMethod}</span>
                            </li> */}
            </ul>
          </div>

          <div className="card">
            <div className="order-list">
              <table>
                <thead className="order-list__header">
                  <tr>
                    <th className="order-list__column-label" colSpan="2">
                      Producto
                    </th>
                    <th className="order-list__column-quantity"></th>
                    <th className="order-list__column-quantity"></th>
                    <th className="order-list__column-quantity">Cantidad</th>
                    {/* <th className="order-list__column-price">Precio</th>
                    <th className="order-list__column-total">Total</th> */}
                  </tr>
                </thead>
                <tbody className="order-list__products">{items}</tbody>
                {additionalLines && additionalLines.length > 0 && (
                  <tbody className="order-list__subtotals">
                    {/* <tr>
                      <th className="order-list__column-label" colSpan="4">
                        Subtotal
                      </th>
                      <td className="order-list__column-total">
                        <Currency value={order.subtotal} />
                      </td>
                    </tr> */}
                    {/* {additionalLines} */}
                  </tbody>
                )}
                {/* <tfoot className="order-list__footer">
                  <tr>
                    <th className="order-list__column-label" colSpan="4">
                      Total
                    </th>
                    <td className="order-list__column-total">
                      <Currency value={(order && order.total) || 0} />
                    </td>
                  </tr>
                </tfoot> */}
              </table>
            </div>
          </div>

          <div className="row mt-3 no-gutters mx-n2">
            {/* <div className="col-sm-6 col-12 px-2">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">
                    Dirección de Entrega
                  </div>
                  <div className="address-card__name">
                    {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
                  </div>
                  <div className="address-card__row">
                    {order.shippingAddress.country}
                    <br />
                    {`${order.shippingAddress.postcode}, ${order.shippingAddress.city}`}
                    <br />
                    {order.shippingAddress.address}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Teléfono</div>
                    <div className="address-card__row-content">
                      {order.shippingAddress.phone}
                    </div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">
                      Correo Electrónico
                    </div>
                    <div className="address-card__row-content">
                      {order.shippingAddress.email}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">
                    Dirección de Facturación
                  </div>
                  <div className="address-card__name">
                    {`${order.billingAddress.firstName} ${order.billingAddress.lastName}`}
                  </div>
                  <div className="address-card__row">
                    {order.billingAddress.country}
                    <br />
                    {`${order.billingAddress.postcode}, ${order.billingAddress.city}`}
                    <br />
                    {order.billingAddress.address}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">
                      {order.billingAddress.phone}
                    </div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email Address</div>
                    <div className="address-card__row-content">
                      {order.billingAddress.email}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

// const mapDispatchToProps = {
//     cartRemoveItem,
//     cartUpdateQuantities
// };

export default connect(mapStateToProps)(ShopPageOrderSuccess);
