// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';
import { toast } from "react-toastify";

import {reviewsApi} from "../../api/reviews";
import productsApi from '../../api/products';
import securityApi from '../../api/security';
import { storageHelper } from '../../helpers/localStorage';
import { useEffect } from 'react';



function ProductCard(props) {
   const { currency } = props;

    const [reviewsCount, setReviewsCount] = React.useState(0); 
    const [loading, setLoading] = React.useState(false); 
    const [loadingCart, setLoadingCart] = React.useState(false); 
    const [isLogged, setIsLogged] = React.useState(false); 
    // const [role, setRole] = React.useState('anonymous'); 

    

    const isUserLogged= async()=>{
      setIsLogged(!await securityApi.isValidToken(storageHelper.getToken()));
      // let obj = await storageHelper.getUserRole();
      // setRole(obj.role);
    }
    useEffect(()=>{
      
      isUserLogged()
    }, [currency])

    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;

    if (product.badges.includes('sale') && product.stock > 0) {
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale ({product.stock})</div>);
    }
    if (product.badges.includes('hot') && product.stock > 0) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot ({product.stock})</div>);
    }
    if (product.badges.includes('new') && product.stock > 0) {
        badges.push(<div key="new" className="product-card__badge product-card__badge--new">New ({product.stock})</div>);
    }
    if (product.stock === 0) {
        badges.push(<div key="outstock" className="product-card__badge product-card__badge--sale">Proximamente ({product.stock})</div>);
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.images && product.images.length > 0) {
        if(isLogged){
          image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={product.images[0]} alt="" />
                </Link>
            </div>
        );
      }else{
        image = (
          <div className="product-card__image product-image">
              <div className="product-image__body">
                  <img className="product-image__img" src={product.images[0]} alt="" />
              </div>
          </div>
      );
      }
    }

  if (!isLogged) {
    price = (
      <div className="product-card__prices">
        {/* <Currency value={product.price} />{" "} */}
        {/* {product.price && (
          <span className="product-card__old-price">
            <Currency value={product.price} />
          </span>
        )} */}
      </div>
    );
  }

  // if (currency.code === 'CUP') {
  //   price = (
  //     <div className="product-card__prices">
  //         <span className="product-card__new-price">{currency.code}{' '}<Currency value={product.price * 250} /></span>
  //     </div>
  //   );
  // }else  if (currency.code === 'MLC') {
  //   price = (
  //     <div className="product-card__prices">
  //         <span className="product-card__new-price">{currency.code}{' '}<Currency value={product.price * 1.15} /></span>
  //     </div>
  //   );
  // }else {
  //   price = (
  //     <div className="product-card__prices">
  //         <span className="product-card__new-price">{currency.code}{' '}<Currency value={product.price} /></span>
  //     </div>
  //   );
  // }
  
  // price = (
  //   <div className="product-card__prices">
  //       <span className="product-card__new-price">{currency.code}{' '}<Currency value={product.price * currency.tasa} /></span>
  //       {/* {' '}
  //       {product.compareAtPrice  && <span className="product-card__old-price"><Currency value={product.compareAtPrice} /></span>} */}
  //   </div>
  // );

  // if (isLogged && role==='business') {
  //       price = (
  //           <div className="product-card__prices">
  //               <span className="product-card__new-price">USD <Currency value={product.price} /></span>
  //               {/* {' '}
  //               {product.compareAtPrice  && <span className="product-card__old-price"><Currency value={product.priceMayoristaAlt} /></span>} */}
  //           </div>
  //       );
  //   } 

  //   if (isLogged && role==='user') {
  //     price = (
  //         <div className="product-card__prices">
  //             <span className="product-card__new-price">USD <Currency value={product.price} /></span>
  //             {/* {' '}
  //             {product.compareAtPrice  && <span className="product-card__cup-price"><Currency value={product.priceAlt} /></span>} */}
  //         </div>
  //     );
  // } 


    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes.filter((x) => x.featured).map((attribute, index) => (
                    <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
                ))}
            </ul>
        );
    }

    React.useEffect(()=>{
        reviewsApi.getByProductId(product).then((response)=>{
          setReviewsCount(response.data.length);
        })
        // var sessData = storageHelper.getUserData();
        // setUserData(sessData);
    }, [])

    const openQuickView = async () => {
      setLoading(true);
      await quickviewOpen(product.slug);
      setLoading(false);
    };

    const addToCart = async(product) => {
        setLoadingCart(true);
        //Validar si hay en existencia
        let prod = await productsApi.get(product.id);
        //console.log(prod);

        if (prod.error && prod.error.name === "UnauthorizedError") {                   
          toast.error(`Inicie sesión para agregar al carrito`);
          setLoadingCart(false);
        } 
        else if (prod && prod.stock > 0) {
          //Update stock
          let newProd = {
            stock: prod.stock - 1,
          };
          await productsApi.updateStock(newProd, product.id);
          await cartAddItem(product);
          setLoadingCart(false);
        } else {
          toast.info(`EL producto ${product.name} no esta disponible`);
          setLoadingCart(false);
        }
    };

    return (
      <div className={containerClasses}>
        <button
          type="button"
          onClick={() => {
            openQuickView();
          }}
          className={classNames("product-card__quickview", {
            "product-card__quickview--preload": loading,
          })}
        >
          <Quickview16Svg />
        </button>
        {badges}
        {image}
        <div className="product-card__info">
          <div className="product-card__name">
            {isLogged && <Link to={url.product(product)}>{product.name}</Link>}
            {!isLogged && <span>{product.name}</span>}
          </div>
          <div className="product-card__rating">
            <Rating value={product.rating} />
            <div className=" product-card__rating-legend">{`${reviewsCount} Comentarios`}</div>
          </div>
          {features}
        </div>
        <div className="product-card__actions">
          <div className="product-card__availability">
            Availability:
            <span className="text-success">{product.availability}</span>
          </div>
          {price}
          <div className="product-card__buttons">
            <React.Fragment>
                <button
                  type="button"
                  onClick={()=>addToCart(product)}
                //   onDoubleClick={()=>{alert()}}
                  className={classNames(
                    "btn btn-primary product-card__addtocart",{"btn-loading disabled": loadingCart}
                  )}
                >
                  Add to Cart
                </button>
              </React.Fragment>
            <AsyncAction
              action={() => wishlistAddItem(product)}
              render={({ run, loading }) => (
                <button
                  type="button"
                  onClick={run}
                  className={classNames(
                    "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                    {
                      "btn-loading": loading,
                    }
                  )}
                >
                  <Wishlist16Svg />
                </button>
              )}
            />
            <AsyncAction
              action={() => compareAddItem(product)}
              render={({ run, loading }) => (
                <button
                  type="button"
                  onClick={run}
                  className={classNames(
                    "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare",
                    {
                      "btn-loading": loading,
                    }
                  )}
                >
                  <Compare16Svg />
                </button>
              )}
            />
          </div>
        </div>
      </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => ({
  currency: state.currency
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
