export default {
    name: 'OFFIX S.U.R.L',
    fullName: 'OFFIX S.U.R.L.',
    url: 'https://www.offixcorp.com',
    author: {
        name: 'OFFIX S.U.R.L',
        profile_url: 'https://www.offixcorp.com',
    },
    contacts: {
        address: 'Calle Martí No. 203 entre Figueredo y Lora, Bayamo, Granma',
        email: 'bussinesoffix@gmail.com',
        phone: '(+53) 23-425039',
        mobile: '(+53) 50926652',
    },
};
