/* eslint-disable no-unused-vars */
// react
import React from "react";
import { useHistory } from "react-router-dom";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "animate.css";
import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
//Api
import addressApi from "../../api/address";

//storage
import { storageHelper } from "../../helpers/localStorage";

import theme from "../../data/theme";

export default function AccountPageAddresses() {
  const [loading, setLoading] = React.useState(false);
  const [userAuth, setuserAuth] = React.useState({});
  const [dataAddresses, setDataAddresses] = React.useState([]);
  const [pais, setPais] = React.useState("");
  const [estado, setEstado] = React.useState("");
  const [ciudad, setCiudad] = React.useState("");

  let history = useHistory();

  React.useEffect(() => {
    const authData = storageHelper.getUserData();
    setuserAuth(authData);
    if (!authData.token) {
      history.push("/");
      return;
    }
    addressApi.getByUser(authData.id).then((response) => {
      setDataAddresses(response);
      //console.log(response);
    });
  }, [history]);

  //console.log(dataAddresses)

  const setPrincipal = (item) => {
    setLoading(true);
    if (!item.isPrincipal) {
      Swal.fire({
        title: "Establecer Dirección Principal",
        text: "¿Seguro que deseas cambiar la dirección principal?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, Cambiar",
        cancelButtonText: "Cancelar",
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          addressApi.getPrincipal(userAuth.id).then((r) => {
            var data  = r.filter(x=>x.isPrincipal)[0];
            data.updatedDate = new Date();
            data.isPrincipal = false;
            let dir1 = 
              {
                "id": data.id,
                "createdDate": data.createdDate,
                "updatedDate": data.updatedDate,
                "enable": data.enabled,
                "name": data.name,
                "lastname": data.lastname,
                "company": data.company,
                "street": data.street,
                "apartament": data.apartament,
                "zip": data.zip,
                "email": data.email,
                "phone": data.phone,
                "website": data.website,
                "isPrincipal": data.isPrincipal,
                "countryId": data.countryId,
                "cityId": data.cityId,
                "stateId": data.stateId,
                "userId": data.userId,
              }
            addressApi.update(dir1, dir1.id).then((response) => {
              item.updatedDate = new Date();
              item.isPrincipal = true;
              let dir2 = 
              {
                "id": item.id,
                "createdDate": item.createdDate,
                "updatedDate": item.updatedDate,
                "enable": item.enabled,
                "name": item.name,
                "lastname": item.lastname,
                "company": item.company,
                "street": item.street,
                "apartament": item.apartament,
                "zip": item.zip,
                "email": item.email,
                "phone": item.phone,
                "website": item.website,
                "isPrincipal": item.isPrincipal,
                "countryId": item.countryId,
                "cityId": item.cityId,
                "stateId": item.stateId,
                "userId": item.userId,
              }
              addressApi.update(dir2, dir2.id).then((r) => {
                toast.info("Se cambio la dirección principal correctamente");
                addressApi.getByUser(userAuth.id).then((response) => {
                  setDataAddresses(response);
                });
                setLoading(false);
              });
            });
          });

          
        } else {
          setLoading(false);
        }
      });
    } 
  };

  const lazyDelete = (item) => {
    setLoading(true);
    if (item.isPrincipal) {
      setLoading(false);
      toast.error(
        "No puede quitar la dirección principal. Antes, establezca otra dirección como principal."
      );
    } else {
      Swal.fire({
        title: "Quitar Dirección",
        text: "¿Seguro que deseas quitar esta dirección?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, Quitar",
        cancelButtonText: "Cancelar",
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          item.enable = false;
          item.updatedDate = new Date();
          addressApi.lazyDelete(item).then((r) => {
            addressApi.getByUser(userAuth.id).then((response) => {
              setDataAddresses(response);
            });
            toast.info("Se quitó la dirección correctamente");
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });
    }
  };

  const addresses = dataAddresses.map((address) => (
    <React.Fragment key={address.id}>
      {/* <div className="addresses-list__item card address-card"> */}
      <div className={address.isPrincipal ? ("addresses-list__item card address-card bg-dark text-white"):("addresses-list__item card address-card bg-default")}>
        {/* {address.isPrincipal && (
          <div className="address-card__badge bg-success">Principal</div>
        )} */}
        {!address.isPrincipal && (
          <button
            type="button"
            onClick={() => setPrincipal(address)}
            className="btn btn btn-xs address-card__badge bg-dark text-white">
            Promover
          </button>
        )}

        <div className="address-card__body bg-default">
        {/* <div className={address.isPrincipal ? ("address-card__body bg-success"):("address-card__body bg-default")}> */}
          <div className="address-card__name"><small><strong>{`${address.name} ${address.lastname}`}</strong></small></div>
          <div className="address-card__row">
            {address.country.name}
            <br />
            {address.state.name}, {address.city.name}, {address.zip}
            <br />
            {address.street}, {" #"}
            {address.apartament}
          </div>
          <div className="address-card__row">
            <div className="address-card__row-title">Teléfono</div>
            <div className="address-card__row-content"><small>{address.phone}</small></div>
          </div>
          <div className="address-card__row">
            <div className="address-card__row-title">Email</div>
            <div className="address-card__row-content"><small>{address.email}</small></div>
          </div>
          <div className="address-card__footer text-light">
            <Link to={`/account/addresses/${address.id}`}>
              <span className="fa fa-edit"></span>Editar
            </Link>
            &nbsp;&nbsp;
            {/* <Link to="/">Quitar</Link> */}
            {!loading && !address.isPrincipal && (
              <button
                type="button"
                onClick={() => lazyDelete(address)}
                className="btn btn-danger btn-xs"
              >
                <span className="fa fa-trash"></span>
                Quitar
              </button>
            )}
            {loading && (
              <button className="btn btn-danger btn-xs" disabled>
                <span className="spinner-border spinner-border-sm mr-1"></span>
                Espere...
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="addresses-list__divider" />
    </React.Fragment>
  ));

  return (
    <div className="addresses-list">
      <Helmet>
        <title>{`Lista de Direcciones — ${theme.name}`}</title>
      </Helmet>

      <Link
        to="/account/address/create"
        className="addresses-list__item addresses-list__item--new"
      >
        <div className="addresses-list__plus" />
        <div className="btn btn-secondary btn-sm">Nueva Dirección</div>
      </Link>
      <div className="addresses-list__divider" />
      {addresses}
    </div>
  );
}
