/* eslint-disable no-unused-vars */
// react
import React from 'react';
import PropTypes from 'prop-types';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useHistory } from 'react-router-dom';
// application
import PageHeader from '../shared/PageHeader';
//Helpers
import { storageHelper } from "../../helpers/localStorage";

import { API_BASE_URL } from "../../environment/environment";
// data stubs
import theme from '../../data/theme';
import typeServiceApi from '../../api/typeService';
import { toast } from 'react-toastify';
// blocks
import BlockLoader from '../blocks/BlockLoader';

function ServicePage() {
    const { key } = useParams();
    let history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [tipoServicios, setTipoServicios] = React.useState([]);

    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: 'Servicios', url: '' },
    ];

    React.useEffect(() => {
        setLoading(true);

        typeServiceApi.getAllWithServices().then((response) => {
            if (!response.error) {
                setTipoServicios(response)
                setLoading(false)
            }
            else {
                setLoading(false)
                toast(response.error, { type: 'error' })
            }
        })
    }, [history])

    const tiposervicios = tipoServicios.map((item, index) => {
        return (
            <div key={index}>
                <div>
                    <PageHeader header={item.name} />
                    <div className="block">
                        <div className="container">
                            <div className="row">
                                {
                                    item.services && item.services.map((item1, index1) => {
                                        return (

                                            <div key={index1} className="product-card__info product-card col-md-3 col-sm-12 m-1">
                                                <div className="pt-4 address-card__name text-center">{item1.name}</div>
                                                <div className='card-body'>
                                                    <img className="" src={`${API_BASE_URL}api/files/${item1.image}`} alt="" width={200} height={200} />
                                                </div>
                                                <div className='card-footer'>
                                                    <div className="text-center">
                                                        <Link className='btn btn-primary' to={`/services/order/create/${item1.id}`}>Solicitar Servicio</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    });


    if (loading) {
        return <BlockLoader />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Servicios — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header={''} breadcrumb={breadcrumb} />
            {tiposervicios}
        </React.Fragment >
    );
}
ServicePage.propTypes = {
    /**
     * product object
     */
    // product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

export default ServicePage;
