import { API_BASE_URL } from "../environment/environment";
import { storageHelper } from "../helpers/localStorage";
import axios from "axios";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
  Authorization: `Bearer ${storageHelper.getToken()}`,
};

export const serviceOrderApi = {
  create: async (data) => {
    const response = await fetch(API_BASE_URL + "api/orderservices", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  update: async (data, id) => {
    const response = await fetch(`${API_BASE_URL}api/orderservices/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    return response.json();
  },
  get: async (id) => {
    const response = await fetch(`${API_BASE_URL}api/orderservices/fulldata/${id}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
  getAll: async () => {
    const response = await fetch(`${API_BASE_URL}api/orderservices/fulldata`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  },
  // getByUserIdFullData: async (id) => {
  //   const response = await fetch(`${API_BASE_URL}api/orderservices/fulldatabyuser/${id}`, {
  //     method: "GET",
  //     headers: headers,
  //   });
  //   return response.json();
  // },
  getByUserIdFullData: async (id) => {
    let response;
    try {
      response = await axios.get(`${API_BASE_URL}api/orderservices/fulldatabyuser/${id}`, {
        headers: headers
      });
    } catch (e) {
     
    }
    // if success return value
    return response;
  },
};

export default serviceOrderApi;
