/* eslint-disable no-unused-vars */
// react
import React from "react";
import { useHistory, useParams } from "react-router-dom";
// third-party
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import {
  Formik,
  Form,
  Field,
  Select,
  useFormik, useFormikContext
} from "formik";
import * as Yup from "yup";
import "animate.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from "../../data/theme";
//Api
import securityApi from "../../api/security";
//Helpers
import { storageHelper } from "../../helpers/localStorage";
import filesApi from "../../api/files";
import { AuthContext } from "../../helpers/auth";
import { API_BASE_URL } from "../../environment/environment";
import { PasswordHelper } from "../../helpers/hashing";

export default function AccountPageForgotPassword() {
  const [userAuth, setuserAuth] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loadingCode, setLoadingCode] = React.useState(false);
  const [resetKey, setresetkey] = React.useState("");
  
  const formikRef = React.useRef();
  
  const breadcrumb = [
    { title: 'Inicio', url: '/' },
    { title: 'Recuperar Contraseña', url: '' },
];

  let history = useHistory();
  const { Login } = React.useContext(AuthContext);
  const swall = withReactContent(Swal);

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Escriba la Contraseña nueva")
      .min(6, "Longitud mínima es 6!"),
    confirmPassword: Yup.string()
      .min(6, "Longitud mínima es 6!")
      .required("Confime la Contraseña")
      .oneOf([Yup.ref("password"), null], "Las Contraseñas no coinciden"),
    resetKey: Yup.string()
      .min(4, "El código debe contener 4 dígitos!")
      .required("Introduzca el código de verificación"),
    email: Yup.string()
      .email("Correo electrónico no válido")
      .required("Complete este campo"),
  });


  const getCode = () => {
    setLoadingCode(true);
    let email =  formikRef.current.values.email;
    securityApi.requestCode({ email: email }).then((response) => {
      
      if (response.error) {
        toast.error(
          `${response.error.message}. El email no se ha enviado. Intente otra vez`
        );
        setLoadingCode(false);
      } else {
        Swal.fire({
          title: `Email enviado`,
          text: `Se ha enviado un email a la dirección ${email}. Copie el código e introdúzcalo en el campo requerido`,
          icon: "success",
          //showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        setLoadingCode(false);
      }
    });
  };

  const resetPassword = (values, { resetForm }) => {
    var obj = {
      password: PasswordHelper.createHash(values.password),
      confirmPassword: PasswordHelper.createHash(values.confirmPassword),
      resetKey: values.resetKey,
    };
    securityApi.resetPassword(obj).then((response) => {
      if (response.error) {
        toast.error(response.error.message);
        setLoading(false);
      } else {
        toast.info("Se ha cambiado la Contraseña correctamente");
        setLoading(false);
        resetForm();
      }
    });
  };

  return (
    
    <div className="container">
      <div className="card">
        <Helmet>
          <title>{`Cambiar Contraseña — ${theme.name}`}</title>
        </Helmet>
        <PageHeader header="Recuperar mi contraseña" breadcrumb={breadcrumb} />


        {/* <div className="card-header">
          <h5>Cambiar Contraseña</h5>
        </div> */}
        <div className="card-divider" />
        <div className="card-body">
          <div className="row no-gutters">
            <div className="col-12 col-lg-7 col-xl-6">
              <Formik
              innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  resetKey: "",
                  email: '',
                }}
                
                validationSchema={changePasswordSchema}
                onSubmit={(values, { resetForm }) => {
                  setLoading(true);
                  resetPassword(values, { resetForm });
                }}
              >
                {({ handleSubmit, errors, touched, dirty }) => (
                  <form>
                    <div className="form-group">
                      <label htmlFor="email">Email de Registro</label>
                      <Field
                        name="email"
                        placeholder=""
                        className="form-control"
                        type="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password-new">Nueva Contraseña</label>
                      <Field
                        name="password"
                        placeholder=""
                        className="form-control"
                        type="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger small">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password-confirm">
                        Confirmar Nueva Contraseña
                      </label>
                      <Field
                        name="confirmPassword"
                        placeholder=""
                        className="form-control"
                        type="password"
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div className="text-danger small">
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="resetKey">Código de Confirmación</label>
                      {!loadingCode && !errors.email && dirty && (
                        <button
                          type="button"
                          onClick={getCode}
                          className="btn btn-dark btn-xs ml-1"
                        >
                          Obtener Código
                        </button>
                      )}
                      {loadingCode && (
                        <button className="btn btn-dark btn-xs ml-1" disabled>
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Espere...
                        </button>
                      )}

                      <Field
                        name="resetKey"
                        placeholder="Introduzca aquí el código enviado a su email"
                        className="form-control"
                      />
                      {errors.resetKey && touched.resetKey ? (
                        <div className="text-danger small">
                          {errors.resetKey}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group mt-5 mb-0">
                      {!loading && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-success"
                        >
                          Cambiar
                        </button>
                      )}
                      {loading && (
                        <button className="btn btn-primary" disabled>
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Espere...
                        </button>
                      )}
                    </div>
                  </form>
                  
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
