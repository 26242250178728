const ErrorHandler =  {
  parseError: (error)=>{
    if(error){
      switch(error.name){
        case "UnauthorizedError":
        return "Usted no tiene permisos suficientes para realizar esta operación";
        break;
        case "Error":
        return "Acceso denegado";
        break;
      }
    }
    return error.message;
  }
}

export default ErrorHandler;