// react
import React, {useState, useEffect} from 'react';

// application
import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';
import {reviewsApi} from "../../api/reviews";

import { API_BASE_URL } from "../../environment/environment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';

//storage
import { storageHelper } from "../../helpers/localStorage";
import productsApi from '../../api/products';

// data stubs
// import reviews from '../../data/shopProductReviews';
// import productsApi from '../../api/products';


function ProductTabReviews(props) {
    const {
      product, 
    } = props;

    const [lReviews, setlReviews] = React.useState([]); 
    const [loading, setLoading] = React.useState(false);
    const [userData, setUserData] = React.useState({});      
    const [currentPage, setCurrentPage] = React.useState(1);      
  
    //Paginar
    function ChangePage(value){
      setCurrentPage(value);
    }
    // End paginar


    React.useEffect(()=>{
        reviewsApi.getByProductId(product).then((response)=>{
          setlReviews(response.data);
        })
        var sessData = storageHelper.getUserData();
        setUserData(sessData);
    }, [])
    
    
    const ReviewSchema = Yup.object().shape({
      email: Yup.string()
        .email("Correo electrónico no válido")
        .required("Complete este campo"),
      author: Yup.string().required("Complete este campo"),
      text: Yup.string().required("Complete este campo"),
      rating: Yup.number().required("Complete este campo"),
      productId: Yup.string().required(""),
      date: Yup.date(),
      avatar: Yup.string()
    });
  
    const CreateReview = async (values, {resetForm})=>{
      setLoading(true);
      var params = values;
      params.rating = parseFloat(values.rating);
      if(userData.avatar === undefined || userData.avatar === null ){
        params.avatar = "default-avatar.png";
      }
      else{
        params.avatar = userData.avatar;
      }

      reviewsApi.create(params).then((r) => {
        if (r.error) {
          toast.info(r.error.message);
          setLoading(false);
        } 
        else {
          reviewsApi.getByProductId(product).then((response)=>{
            //Update review count
            productsApi.updateReviews({reviews: product.reviews + 1 }, product.id).then((r)=>{
              setlReviews(response.data);
            })
            //update rating
            var newRating = params.rating;
            lReviews.map((r)=>{
                newRating += r.rating;
            });
            console.log(lReviews.length);
            console.log(newRating);

            productsApi.updateRating({ rating: (newRating) / (lReviews.length +1)  }, product.id)
              .then((res) => {
                toast.info("La valoracion ha sido completada");
                setLoading(false);
                resetForm();
              });
          })
        }
      });
    }

    const reviewsList = lReviews.map((review, index) => (
        <li key={index} className="reviews-list__item">
            <div className="review">
                <div className="review__avatar"><img style={{width:80, height:80}} src={`${API_BASE_URL}api/files/${review.avatar}`} alt="" /></div>
                <div className=" review__content">
                    <div className=" review__author">{review.author}</div>
                    <div className=" review__rating">
                        <Rating value={review.rating} />
                    </div>
                    <div className=" review__text">{review.text}</div>
                    <div className=" review__date">{review.date}</div>
                </div>
            </div>
        </li>
    ));
    return (
      <div className="reviews-view">
        {lReviews && lReviews.length > 0 && (
          <div className="reviews-view__list">
            <h3 className="reviews-view__header">Valoración de los clientes</h3>

            <div className="reviews-list">
              <ol className="reviews-list__content">{reviewsList}</ol>
              <div className="reviews-list__pagination">
                <Pagination
                  current={currentPage}
                  siblings={2}
                  total={lReviews.length / 2}
                  onPageChange={(value)=>{ChangePage(value)}}
                />
                {/* <ReactPaginate
                  breakLabel="..."
                  nextLabel=" >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                /> */}
              </div>
            </div>
          </div>
        )}

        <Formik
          initialValues={{
            email: "",
            author: "",
            text: "",
            productId: product.id,
            date: new Date().toDateString(),
            avatar: "",
          }}
          validationSchema={ReviewSchema}
          onSubmit={(values, { resetForm }) => {
            CreateReview(values, { resetForm });
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <form className="reviews-view__form">
              <h3 className="reviews-view__header">
                Valora nuestros productos
              </h3>
              <div className="row">
                <div className="col-12 col-lg-9 col-xl-8">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="review-author">Nombre</label>
                      <Field
                        name="author"
                        placeholder="Escribe tu nombre"
                        className="form-control form-control-sm"
                      />
                      {errors.author && touched.author ? (
                        <div className="text-danger small">{errors.author}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="review-email">Correo Electónico</label>
                      <Field
                        name="email"
                        placeholder="Correo electrónico"
                        className="form-control form-control-sm"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="review-stars">Puntuación</label>
                      <Field
                        as="select"
                        name="rating"
                        id="rating"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("rating", e.target.value);
                        }}
                      >
                        <option value={0}> - Seleccionar - </option>
                        <option value={5}>5 Estrellas</option>
                        <option value={4}>4 Estrellas</option>
                        <option value={3}>3 Estrellas</option>
                        <option value={2}>2 Estrellas</option>
                        <option value={1}>1 Estrellas</option>
                      </Field>
                      {errors.rating && touched.rating ? (
                        <div className="text-danger small">{errors.rating}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="review-text">Comentario</label>
                    <Field
                      name="text"
                      as="textarea"
                      rows="6"
                      placeholder="Comentario"
                      className="form-control form-control-sm"
                    />
                    {errors.text && touched.text ? (
                      <div className="text-danger small">{errors.text}</div>
                    ) : null}
                  </div>
                  <div className="form-group mb-0">
                    {!loading && (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-lg"
                      >
                        Enviar Valoración
                      </button>
                    )}
                    {loading && (
                      <button className="btn btn-primary btn-lg" disabled>
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                        Enviando ...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
}

export default ProductTabReviews;
