/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
// react
import React from "react";
import { useHistory } from "react-router-dom";

// third-party
import { Helmet } from "react-helmet-async";
import { Formik, Form, Field, Select, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
//storage
import { storageHelper } from "../../helpers/localStorage";
import { AuthContext } from "../../helpers/auth";
// data stubs
import theme from "../../data/theme";
//Api
import { profileApi } from "../../api/profile";
import countriesApi from "../../api/countries";

export default function AccountPageProfile(props) {
  const { userData } = props;
  const { Login, Logoff } = React.useContext(AuthContext);

  const [token, setToken] = React.useState("");
  const [userAuth, setuserAuth] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [country, setCountry] = React.useState([]);
  const [Profile, setProfile] = React.useState({});

  let history = useHistory();

  React.useEffect(() => {
    const authData = storageHelper.getUserData();
    setuserAuth(authData);
    if (!authData.token) {
      history.push("/");
    }
  }, [history]);

  React.useEffect(() => {
    //Load active profile
    profileApi.getByUserId(userAuth.id).then((response) => {
      const data = response[0];
      if (data !== undefined) {
        data.enable = true;
        data.createdDate = new Date();
        data.updatedDate = new Date();
      }
      setProfile(data);
    });
  }, [userAuth.id, history]);

  const loadProfile = () => {
    if (Profile) {
      return Profile;
    } else {
      return {
        name: "",
        lastname: "",
        userId: userAuth.id,
        clicode: "NO APROBADO",
        enable: true,
        createdDate: new Date(),
        updatedDate: new Date(),
      };
    }
  };

  const FillProfile = (data) => {
    //Verificar si tiene perfil creado
    let profileId = "";
    profileApi.getByUserId(userAuth.id).then((response) => {
      if (response["error"]) {
        toast.warning(response["error"].message);
        setLoading(false);
        return;
      } else if (response.length > 0) {
        profileId = response[0].id;
        if (profileId !== "") {
          //Update Profile
          profileApi.update(data, profileId).then((response) => {
            if (response["error"]) {
              toast.warning(response["error"].message);
              setLoading(false);
            } else {
              var sessData = storageHelper.getUserData();
              sessData.name = data.name;
              storageHelper.setUserData(sessData);
              Login(sessData);
              setLoading(false);
              toast.info("Su perfil ha sido actualizado correctamente", {
                icon: "check",
              });
            }
          });
        }
      } else {
        profileApi.create(data).then((response) => {
          var sessData = storageHelper.getUserData();
          sessData.name = data.name;
          storageHelper.setUserData(sessData);
          Login(sessData);
          setLoading(false);
          toast.info("Su perfil ha sido actualizado correctamente");
        });
      }
    });
  };

  //Formik Schemas
  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required("Complete este campo"),
    lastname: Yup.string().required("Complete este campo"),
    phone: Yup.string(),
    clicode: Yup.string(),
    photo: Yup.string(),
    website: Yup.string(),
    ruccode: Yup.string(),
    userId: Yup.string(),
    stateId: Yup.string(),
    cityId: Yup.string(),
    enable: Yup.boolean(),
    createdDate: Yup.string(),
    updatedDate: Yup.string(),
  });
  const ProfileSchemaBussines = Yup.object().shape({
    name: Yup.string().required("Introduzca el nombre de su empresa"),
    lastname: Yup.string(),
    phone: Yup.string(),
    clicode: Yup.string(),
    photo: Yup.string(),
    website: Yup.string(),
    ruccode: Yup.string().required("Introduzca el código REEUP de su empresa"),
    userId: Yup.string(),
    stateId: Yup.string(),
    cityId: Yup.string(),
    enable: Yup.boolean(),
    createdDate: Yup.string(),
    updatedDate: Yup.string(),
  });

  const formLabel =
    userAuth.role === "admin" ? (
      <h5>Editar Perfil </h5>
    ) : userAuth.role === "user" ? (
      <h5>Editar Perfil Personal</h5>
    ) : (
      <h5>Editar Perfil Empresarial</h5>
    );

  return (
    <div className="card">
      <Helmet>
        <title>{`Perfil — ${theme.name}`}</title>
      </Helmet>
      <div className="card-header">{formLabel}</div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-7 col-xl-6">
            {userAuth.role === "user" && (
              <Formik
                enableReinitialize={true}
                initialValues={loadProfile()}
                validationSchema={ProfileSchema}
                onSubmit={(values, { resetForm }) => {
                  setLoading(true);
                  FillProfile(values, { resetForm });
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form>
                    <div className="form-group">
                      <label htmlFor="profile-first-name">Nombre</label>
                      <Field
                        name="name"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-danger small">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-last-name">Apellidos</label>
                      <Field
                        name="lastname"
                        placeholder=""
                        className="form-control"
                      />
                      {errors.lastname && touched.lastname ? (
                        <div className="text-danger small">
                          {errors.lastname}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-website">CI/PASAPORTE</label>
                      <Field
                        name="ruccode"
                        placeholder=""
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mt-5 mb-0">
                      {!loading && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-success"
                        >
                          Actualizar
                        </button>
                      )}
                      {loading && (
                        <button className="btn btn-primary" disabled>
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Espere...
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            )}
            {userAuth.role === "business" && (
              <Formik
                enableReinitialize={true}
                initialValues={loadProfile()}
                validationSchema={ProfileSchemaBussines}
                onSubmit={(values, { resetForm }) => {
                  setLoading(true);
                  FillProfile(values, { resetForm });
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form>
                    <div className="form-group">
                      <label htmlFor="profile-first-name">
                        Codigo del Cliente
                      </label>
                      <Field
                        name="clicode"
                        placeholder=""
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-first-name">Nombre</label>
                      <Field
                        name="name"
                        placeholder="Nombre"
                        className="form-control"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-danger small">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-last-name">REEUP</label>
                      <Field
                        name="ruccode"
                        placeholder="Reeup"
                        className="form-control"
                      />
                      {errors.ruccode && touched.ruccode ? (
                        <div className="text-danger small">
                          {errors.ruccode}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-website">Website</label>
                      <Field
                        name="website"
                        placeholder="www.miwebsite.com"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mt-5 mb-0">
                      {!loading && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-success"
                        >
                          Actualizar
                        </button>
                      )}
                      {loading && (
                        <button className="btn btn-primary" disabled>
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Espere...
                        </button>
                      )}
                      {/* <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}>
                        Guardar
                      </button> */}
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
