/* eslint-disable no-unused-vars */
// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";
import constactApi from "../../api/contact";
// import FooterContacts from "../footer/FooterContacts";

function SitePageContactUsAlt() {
  const breadcrumb = [
    { title: "Inicio", url: "/" },
    { title: "Contáctenos", url: "" },
  ];

  const [loading, setLoading] = React.useState(false);

  const ContactSchema = Yup.object().shape({
    email: Yup.string()
      .email("Correo electrónico no válido")
      .required("Complete este campo"),
    name: Yup.string()
      .max(50, "Longitud máxima es 50!")
      .required("Complete este campo"),
    subject: Yup.string()
      .max(20, "Longitud máxima es 20!")
      .required("Complete este campo"),
    body: Yup.string()
      .required("Complete este campo"),
  });

  function sendMessage(data, { resetForm }) {
    setLoading(true);
    constactApi.sendMessage(data).then((response) => {
      if (!response.error) {
        setTimeout(() => {
          toast.info("El mensaje se ha enviado correctamente");
          setLoading(false);
          resetForm()
        }, 500);
      } else {
        setTimeout(() => {
          setLoading(false);
          toast.error(response.error.message);
        }, 500);
      }
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contáctenos — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Contáctenos" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="card mb-0 contact-us">
            <div className="contact-us__map">
              <iframe
                title="Offix S.U.R.L"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=20.3720900,%20-76.6466240+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              />
            </div>
            <div className="card-body">
              <div className="contact-us__container">
                <div className="row">
                  <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                    {/* <FooterContacts /> */}
                    <img
                      alt="..."
                      src={
                        require("../../img/sidebar-4.jpg")
                          .default
                      }
                    ></img>
                  </div>

                  <div className="col-12 col-lg-6">
                    <h4 className="contact-us__header card-title">
                      Déjanos un mensaje
                    </h4>

                    <Formik
                      initialValues={{
                        email: "",
                        name: "",
                        subject: "",
                        body: "",
                        createdDate: new Date(),
                        updatedDate: new Date(),
                        enable: true
                      }}
                      validationSchema={ContactSchema}
                      onSubmit={(values, { resetForm }) => {
                        values.createdDate = new Date();
                        values.updatedDate = new Date();
                        values.enable = true;
                        sendMessage(values, { resetForm });
                      }}
                    >
                      {({ handleSubmit, errors, touched }) => (
                        <Form className="pt-3">
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label htmlFor="name">
                                Nombre
                              </label>
                              <Field
                                name="name"
                                className="form-control"
                              />
                              {errors.name && touched.name ? (
                                <div className="text-danger small">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="form-email">Email</label>
                              <Field
                                name="email"
                                className="form-control"
                              />
                              {errors.email && touched.email ? (
                                <div className="text-danger small">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="subject">Asunto</label>
                            <Field
                              name="subject"
                              className="form-control"
                            />
                            {errors.subject && touched.subject ? (
                              <div className="text-danger small">
                                {errors.subject}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="body">Mensaje</label>
                            <Field as="textarea"
                              name="body"
                              className="form-control"
                              rows="4"
                            />
                            {errors.body && touched.body ? (
                              <div className="text-danger small">
                                {errors.body}
                              </div>
                            ) : null}
                          </div>
                          {!loading && (
                            <button type="submit" className="btn btn-success">
                              Enviar
                            </button>
                          )}
                          {loading && (
                            <button className="btn btn-primary" disabled>
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                              Espere..
                            </button>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageContactUsAlt;
